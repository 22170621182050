import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import database from '../../../assets/markdown/database/start.md'
import frontend from '../../../assets/markdown/frontend/start.md'
import backend from '../../../assets/markdown/backend/start.md'

function TrainDoc(props) {
    const [data, setData] = useState(null)
    useEffect(() => {
        if (
            props.match.params.doc === 'frontend' ||
            props.match.params.doc === 'backend' ||
            props.match.params.doc === 'database'
        ) {
            let document =
                props.match.params.doc === 'frontend'
                    ? frontend
                    : props.match.params.doc === 'backend'
                    ? backend
                    : database
            fetch(document)
                .then((response) => response.text())
                .then((text) => {
                    setData({ data: text })
                })
        }
    }, [props && props.match.params.doc])
    useEffect(() => {
        if (data) {
            console.log(data, 'data')
        }
    }, [data])
    return (
        <div className="customContainer py-3">
            <div className="docContainer">
                <ReactMarkdown
                    remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                    rehypePlugins={[rehypeRaw]}
                >
                    {data ? data.data : 'loading...'}
                </ReactMarkdown>
            </div>
        </div>
    )
}
export default TrainDoc
