import { GET_ALL_EMPLOYEES, GET_SINGLE_EMPLOYEE, CLEAR_RESPONSE, RESPONSE_STATUS } from './empTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_EMPLOYEES:
            return {
                ...state,
                all_employees: action.payload,
            }
        case GET_SINGLE_EMPLOYEE:
            return {
                ...state,
                single_employee: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
