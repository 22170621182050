import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten, makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import FilterListIcon from '@material-ui/icons/FilterList'
import { Visibility, Edit } from '@material-ui/icons'
import {
    dateFormatFunction,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
} from './../../../common/components'
import NoRecordsFound from '../../atoms/NoRecordsFound'
import { Button } from '@material-ui/core'
import Loaders from '../Loaders'
import './CustomTable.css'

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    title: {
        flex: '1 1 100%',
    },
}))

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    container: {
        maxHeight: 440,
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}))

const CustomTable = (props) => {
    const classes = useStyles()
    const [selected, setSelected] = React.useState([])
    const [dense, setDense] = React.useState(false)
    const tableBody = props.tableBody
    const tableValues = props.tableValues
    const tableActions = props.tableActions
    const allData = props.allData
    const formik = props.formik
    const changeTableFunction = props.changeTableFunction

    useEffect(() => {
        setSelected([])
        return () => {
            setSelected([])
        }
    }, [props.tableBody])

    const EnhancedTableHead = (props) => {
        const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
            props
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property)
        }
        return (
            <TableHead>
                <TableRow>
                    {tableActions.length > 0 && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                indeterminate={numSelected > 0 && numSelected < rowCount}
                                checked={rowCount > 0 && numSelected === rowCount}
                                onChange={(e) => {
                                    onSelectAllClick(e)
                                }}
                                inputProps={{ 'aria-label': 'select all desserts' }}
                            />
                        </TableCell>
                    )}
                    {tableValues.map((headCell, index) => (
                        <>
                            {headCell.sort ? (
                                <TableCell
                                    key={index}
                                    align="left"
                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                    sortDirection={orderBy === headCell.field ? order : false}
                                >
                                    <TableSortLabel
                                        active={orderBy === headCell.field}
                                        direction={orderBy === headCell.field ? order : 'asc'}
                                        onClick={createSortHandler(
                                            headCell.sortField
                                                ? headCell.sortField
                                                : headCell.field,
                                        )}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.field ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc'
                                                    ? 'sorted descending'
                                                    : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ) : (
                                <TableCell
                                    key={index}
                                    align="left"
                                    padding={headCell.disablePadding ? 'none' : 'default'}
                                >
                                    {headCell.label}
                                </TableCell>
                            )}
                        </>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    }

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles()
        const { numSelected } = props

        return (
            <Toolbar
                className={clsx(
                    classes.root,
                    'tableToolbar d-flex justify-content-between align-items-center',
                    {
                        [classes.highlight]: numSelected > 0,
                    },
                )}
            >
                {numSelected > 0 ? (
                    <>
                        <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} Selected
                        </Typography>

                        {tableActions.map((data, indexhead) => {
                            return (
                                <>
                                    <Tooltip title={data.label}>
                                        <IconButton
                                            onClick={() => {
                                                data.onclick(selected, data.type)
                                            }}
                                            aria-label={data.label}
                                        >
                                            {data.icon}
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                        })}
                    </>
                ) : (
                    <>
                        <FormControlLabel
                            label="Compact View"
                            control={<Switch checked={dense} onChange={handleChangeDense} />}
                        />

                        {/* <Tooltip title="Filter list">
                            <IconButton aria-label="filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip> */}
                    </>
                )}
            </Toolbar>
        )
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    }

    const handleRequestSort = (event, property) => {
        const isAsc = formik.orderby === property && formik.order === 'asc'
        formik.order = isAsc ? 'desc' : 'asc'
        formik.orderby = property
        formik.page = 1
        changeTableFunction(formik)
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableBody.map((n) => n.id)
            setSelected(newSelecteds)
            return
        } else {
            setSelected([])
        }
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        formik.page = newPage + 1
        changeTableFunction(formik)
    }

    const handleChangeRowsPerPage = (event) => {
        formik.limit = parseInt(event.target.value, 10)
        formik.page = 1
        changeTableFunction(formik)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    var evaluate = (c) => Function(`return ${c}`)()

    const conditionalFormatting = (row, valhead) => {
        let returnValue = row[valhead.field] || '-'
        if (
            valhead.condition &&
            valhead.conditionValue &&
            valhead.conditionValue.formula.isString
        ) {
            let condition = valhead.conditionValue
            if (
                evaluate(
                    `"${row[condition.formula.key1]}" ${condition.formula.notation} "${
                        condition.formula.key2
                    }"`,
                )
            ) {
                if (condition.true.type === 'text') {
                    returnValue = condition.true.value
                } else if (condition.true.type === 'key') {
                    returnValue = row[condition.true.value]
                }
            } else {
                if (condition.false.type === 'text') {
                    returnValue = condition.false.value
                } else if (condition.false.type === 'key') {
                    returnValue = row[condition.false.value]
                }
            }
        } else if (valhead.condition) {
            if (valhead.conditionValue) {
                let condition = valhead.conditionValue
                if (
                    evaluate(
                        `${row[condition.formula.key1]} ${condition.formula.notation} ${
                            condition.formula.key2
                        }`,
                    )
                ) {
                    if (condition.true.type === 'text') {
                        returnValue = condition.true.value
                    } else if (condition.true.type === 'key') {
                        returnValue = row[condition.true.value]
                    }
                } else {
                    if (condition.false.type === 'text') {
                        returnValue = condition.false.value
                    } else if (condition.false.type === 'key') {
                        returnValue = row[condition.false.value]
                    }
                }
            }
        }
        return returnValue
    }

    return (
        <div className={classes.root}>
            {props.isLoading ? (
                <Loaders
                    name={`${props.loadingTemplate ? props.loadingTemplate : 'table'}`}
                    isLoading={props.isLoading}
                />
            ) : (
                <>
                    {tableBody.length ? (
                        <>
                            <Paper className={classes.paper}>
                                <EnhancedTableToolbar numSelected={selected.length} />
                                <TableContainer className={classes.container}>
                                    <Table
                                        stickyHeader
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={formik.order}
                                            orderBy={formik.orderby}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={tableBody.length}
                                        />
                                        <TableBody>
                                            {tableBody.map((row, indexhead) => {
                                                const isItemSelected = isSelected(row.id)
                                                const labelId = `enhanced-table-checkbox-${indexhead}`

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={indexhead}
                                                        selected={isItemSelected}
                                                    >
                                                        {tableActions.length > 0 && (
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    onClick={(event) =>
                                                                        handleClick(event, row.id)
                                                                    }
                                                                    checked={isItemSelected}
                                                                    inputProps={{
                                                                        'aria-labelledby': labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        )}
                                                        {tableValues.map((valhead, index) => {
                                                            return (
                                                                <>
                                                                    <TableCell
                                                                        className={`${
                                                                            valhead.type ===
                                                                                'datetime' &&
                                                                            'ws_noWrap'
                                                                        }`}
                                                                    >
                                                                        {valhead.type == 'image' ? (
                                                                            <img
                                                                                className={`tableimage ${
                                                                                    dense && 'small'
                                                                                }`}
                                                                                src={
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                }
                                                                                alt={row.title}
                                                                                onError={(e) =>
                                                                                    (e.target.src =
                                                                                        '/assets/images/noImg.png')
                                                                                }
                                                                            />
                                                                        ) : valhead.type ==
                                                                          'input' ? (
                                                                            <input
                                                                                value={
                                                                                    valhead.value[
                                                                                        valhead.field +
                                                                                            row[
                                                                                                valhead
                                                                                                    .clickID
                                                                                            ]
                                                                                    ] > -1
                                                                                        ? valhead
                                                                                              .value[
                                                                                              valhead.field +
                                                                                                  row[
                                                                                                      valhead
                                                                                                          .clickID
                                                                                                  ]
                                                                                          ]
                                                                                        : row[
                                                                                              valhead
                                                                                                  .field
                                                                                          ]
                                                                                }
                                                                                onKeyUp={(e) =>
                                                                                    valhead.onClick(
                                                                                        e,
                                                                                        row[
                                                                                            valhead
                                                                                                .clickID
                                                                                        ],
                                                                                    )
                                                                                }
                                                                                onChange={(e) =>
                                                                                    valhead.onChange(
                                                                                        e,
                                                                                        row[
                                                                                            valhead
                                                                                                .clickID
                                                                                        ],
                                                                                    )
                                                                                }
                                                                                name={row.title}
                                                                                disabled={
                                                                                    valhead.disabled ||
                                                                                    false
                                                                                }
                                                                            />
                                                                        ) : valhead.type ==
                                                                          'imagewithurl' ? (
                                                                            <>
                                                                                {row[
                                                                                    'avatar_type'
                                                                                ] === 2 ? (
                                                                                    <img
                                                                                        className={`tableimage ${
                                                                                            dense &&
                                                                                            'small'
                                                                                        }`}
                                                                                        src={`${
                                                                                            row[
                                                                                                valhead
                                                                                                    .field
                                                                                            ]
                                                                                        }`}
                                                                                        alt={
                                                                                            row.title
                                                                                        }
                                                                                        onError={(
                                                                                            e,
                                                                                        ) =>
                                                                                            (e.target.src =
                                                                                                '/assets/images/noImg.png')
                                                                                        }
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        className={`tableimage ${
                                                                                            dense &&
                                                                                            'small'
                                                                                        }`}
                                                                                        src={`${
                                                                                            global.site_url
                                                                                        }/uploads/${
                                                                                            valhead.folder
                                                                                        }/${
                                                                                            row[
                                                                                                valhead
                                                                                                    .field
                                                                                            ]
                                                                                        }`}
                                                                                        alt={
                                                                                            row.title
                                                                                        }
                                                                                        onError={(
                                                                                            e,
                                                                                        ) =>
                                                                                            (e.target.src =
                                                                                                '/assets/images/noImg.png')
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        ) : valhead.type ==
                                                                          'imagewithcompressedurl' ? (
                                                                            <img
                                                                                className={`tableimage ${
                                                                                    dense && 'small'
                                                                                }`}
                                                                                src={`${
                                                                                    global.site_url
                                                                                }/uploads/${
                                                                                    valhead.isCompress
                                                                                        ? valhead.folder +
                                                                                          '/compressed'
                                                                                        : valhead.folder
                                                                                }/${
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                }`}
                                                                                alt={row.title}
                                                                                onError={(e) =>
                                                                                    (e.target.src =
                                                                                        '/assets/images/noImg.png')
                                                                                }
                                                                            />
                                                                        ) : valhead.type ==
                                                                          'date' ? (
                                                                            dateFormatFunction(
                                                                                row[valhead.field],
                                                                            )
                                                                        ) : valhead.type ==
                                                                          'datetime' ? (
                                                                            dateTimeFormatFunction(
                                                                                row[valhead.field],
                                                                            )
                                                                        ) : valhead.type ==
                                                                          'price' ? (
                                                                            row[valhead.field] ? (
                                                                                currencyFormat(
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ],
                                                                                )
                                                                            ) : (
                                                                                '-'
                                                                            )
                                                                        ) : valhead.type ==
                                                                          'capitalize' ? (
                                                                            capitalize(
                                                                                row[valhead.field],
                                                                            )
                                                                        ) : valhead.type ==
                                                                          'payment_method' ? (
                                                                            row.payment_method !==
                                                                            'cash' ? (
                                                                                `${row.mk_card_number} (${row.card_type}) `
                                                                            ) : (
                                                                                capitalize(
                                                                                    row.payment_method,
                                                                                )
                                                                            )
                                                                        ) : valhead.type ==
                                                                          'button' ? (
                                                                            valhead.isMultiple ? (
                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                    {valhead.isMultiple.map(
                                                                                        (
                                                                                            inner,
                                                                                            innerIndex,
                                                                                        ) => (
                                                                                            <div
                                                                                                key={
                                                                                                    innerIndex
                                                                                                }
                                                                                            >
                                                                                                <Tooltip
                                                                                                    title={
                                                                                                        inner.tooltipTitle
                                                                                                            ? inner.tooltipTitle
                                                                                                            : valhead.label
                                                                                                    }
                                                                                                >
                                                                                                    <Button
                                                                                                        onClick={() => {
                                                                                                            inner.onclick(
                                                                                                                valhead.clickID &&
                                                                                                                    !inner.clickID
                                                                                                                    ? row[
                                                                                                                          valhead
                                                                                                                              .clickID
                                                                                                                      ]
                                                                                                                    : inner.clickID
                                                                                                                    ? row[
                                                                                                                          inner
                                                                                                                              .clickID
                                                                                                                      ]
                                                                                                                    : row.id,
                                                                                                                valhead.secondaryClickID
                                                                                                                    ? row[
                                                                                                                          valhead
                                                                                                                              .secondaryClickID
                                                                                                                      ]
                                                                                                                    : inner.secondaryClickID
                                                                                                                    ? row[
                                                                                                                          inner
                                                                                                                              .secondaryClickID
                                                                                                                      ]
                                                                                                                    : null,
                                                                                                                inner.clickType,
                                                                                                            )
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            valhead.disabled
                                                                                                                ? row[
                                                                                                                      valhead
                                                                                                                          .disabled
                                                                                                                  ]
                                                                                                                : false
                                                                                                        }
                                                                                                    >
                                                                                                        <span className="material-icons">
                                                                                                            {inner.buttonType
                                                                                                                ? inner.buttonType
                                                                                                                : 'edit'}
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </Tooltip>
                                                                                            </div>
                                                                                        ),
                                                                                    )}
                                                                                </div>
                                                                            ) : (
                                                                                <Tooltip
                                                                                    title={
                                                                                        valhead.tooltipTitle
                                                                                            ? valhead.tooltipTitle
                                                                                            : valhead.label
                                                                                    }
                                                                                >
                                                                                    <Button
                                                                                        onClick={() => {
                                                                                            valhead.onclick(
                                                                                                valhead.clickID
                                                                                                    ? row[
                                                                                                          valhead
                                                                                                              .clickID
                                                                                                      ]
                                                                                                    : row.id,
                                                                                                valhead.secondaryClickID
                                                                                                    ? row[
                                                                                                          valhead
                                                                                                              .secondaryClickID
                                                                                                      ]
                                                                                                    : null,
                                                                                                valhead.clickType,
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <span className="material-icons">
                                                                                            {valhead.buttonType
                                                                                                ? valhead.buttonType
                                                                                                : 'edit'}
                                                                                        </span>
                                                                                    </Button>
                                                                                </Tooltip>
                                                                            )
                                                                        ) : (
                                                                            <Tooltip
                                                                                title={
                                                                                    valhead.field ===
                                                                                    'bidscount'
                                                                                        ? valhead.label
                                                                                        : ''
                                                                                }
                                                                            >
                                                                                <span
                                                                                    className={`${
                                                                                        valhead?.click
                                                                                            ? 'cursorDecoy'
                                                                                            : ''
                                                                                    } `}
                                                                                    onClick={() =>
                                                                                        valhead.click
                                                                                            ? valhead.onclick(
                                                                                                  valhead.clickID
                                                                                                      ? row[
                                                                                                            valhead
                                                                                                                .clickID
                                                                                                        ]
                                                                                                      : row.id,
                                                                                                  valhead.secondaryClickID
                                                                                                      ? row[
                                                                                                            valhead
                                                                                                                .secondaryClickID
                                                                                                        ]
                                                                                                      : null,
                                                                                                  valhead.clickType,
                                                                                              )
                                                                                            : ''
                                                                                    }
                                                                                >
                                                                                    {valhead.field?.split(
                                                                                        ',',
                                                                                    ).length > 1 ? (
                                                                                        <>
                                                                                            {valhead.field
                                                                                                .split(
                                                                                                    ',',
                                                                                                )
                                                                                                .map(
                                                                                                    (
                                                                                                        value,
                                                                                                        index,
                                                                                                    ) =>
                                                                                                        `${
                                                                                                            row[
                                                                                                                value
                                                                                                            ] ||
                                                                                                            ''
                                                                                                        } `,
                                                                                                )}
                                                                                        </>
                                                                                    ) : row[
                                                                                          valhead
                                                                                              .field
                                                                                      ] !== null ? (
                                                                                        conditionalFormatting(
                                                                                            row,
                                                                                            valhead,
                                                                                        )
                                                                                    ) : (
                                                                                        '-'
                                                                                    )}
                                                                                </span>
                                                                            </Tooltip>
                                                                        )}
                                                                    </TableCell>
                                                                </>
                                                            )
                                                        })}
                                                    </TableRow>
                                                )
                                            })}
                                            {/* {emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={12} />
                                        </TableRow>
                                    )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <TablePagination
                                    rowsPerPageOptions={
                                        [5, 20, 40, 60].includes(formik.limit)
                                            ? [5, 20, 40, 60]
                                            : [formik.limit, 5, 20, 40, 60]
                                    }
                                    component="div"
                                    count={allData.totalRecords}
                                    rowsPerPage={formik.limit}
                                    page={formik.page - 1}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </Paper>
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                </>
            )}
        </div>
    )
}

export default CustomTable
