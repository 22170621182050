import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SettingContext from '../../../common/context/setting/settingContext'

const TemplateManage = (props) => {
    const settingContext = useContext(SettingContext)

    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingContext

    const [product, setProduct] = useState(null)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        comment: Yup.string().required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            id: 0,
            comment: '',
            commit_comment: '',
            date: '',
            enable: 0,
            table: 'bit_bucket_version_info',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            searchTableAction(values)
        },
    })

    // useEffect(() => {
    //     resetFrom()
    // }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            //getSingleConfiguration(formik.values);
            searchTable(
                {
                    limit: 1,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {
                        id: {
                            value: data.id,
                            type: 'in',
                            field: 'bt.id',
                        },
                    },
                    table: 'bit_bucket_version_info',
                },
                'editVersion',
            )
        } else {
            resetFrom()
        }
        return () => {
            clearResponseSetting()
        }
    }, [data.id])
    useEffect(() => {
        if (table_data && table_data.from === 'editVersion') {
            console.log(table_data, 'editVersion')
            const record = table_data.records[0]
            formik.setFieldValue('id', record.id)
            formik.setFieldValue('comment', record.comment)
            formik.setFieldValue('commit_comment', record.commit_comment)
            formik.setFieldValue('date', record.date)
            formik.setFieldValue('enable', record.enable)
        }
    }, [table_data])
    /*useEffect(() => {
    if (single_configuration.record && data.status === "edit") {
      const template = single_configuration.record;

      setProduct(template);
      formik.values.id = template.id;
      formik.values.type = template.type;
      formik.values.question = template.question;
      formik.values.variable = template.variable;

      if (template.type === "checkbox") {
        formik.setFieldValue("value", template.value === "1" ? true : false);
      } else {
        formik.setFieldValue("value", template.value);
      }
    } else {
      resetFrom();
    }
  }, [single_configuration]);*/
    useEffect(() => {
        if (
            responseStatusSetting &&
            responseStatusSetting.status === 'success' &&
            responseStatusSetting.from === 'CommunicationSearchTable'
        ) {
            toggleFullScreenPopup(false)
        }
        return () => {
            clearResponseSetting()
        }
    }, [responseStatusSetting])
    console.log('template.value', formik.values.value)

    const resetFrom = () => {
        formik.values.id = 0

        formik.values.comment = ''
        setProduct(null)
        formik.handleReset()
    }

    const emailTemplate = []

    /*if (data.status === "new") {
    emailTemplate.push(
      {
        label: "Variable",
        placeholder: "Enter Variable",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "variable",
        formik: formik,
      },
      {
        label: "Question",
        placeholder: "Enter Question",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "question",
        formik: formik,
      },
      {
        label: "Type",
        placeholder: "Enter type",
        class: "col-12 mb-4",
        type: "select",
        options: [
          { value: "checkbox", show: "Checkbox" },
          { value: "text", show: "Text" },
          { value: "number", show: "Number" },
        ],
        name: "type",
        formik: formik,
      }
    );
  }*/
    emailTemplate.push({
        label: 'Value *',
        placeholder: `Enter ${'Value'}`,
        class: 'col-12',
        name: 'comment',
        type: 'ckeditor',
        formik: formik,
    })

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD COMMENT'
                    : `EDIT COMMENT: ${product && product.variable}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {formik.values.commit_comment && (
                            <div className="row">{Object.values(mapData(emailTemplate))}</div>
                        )}

                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
