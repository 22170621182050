import { React, useState, useContext } from 'react'
import { Tab, Row, Sonnet, Col, Nav, Navbar, Container } from 'react-bootstrap'
import './style.css'
import authContext from '../../context/auth/authContext'

function Architecture() {
    const { isAuthenticated, user, logout } = useContext(authContext)

    const [toggleClass, setToggleClass] = useState(1)
    return (
        <div className="archDiagramCnt customContainer" id="architectureDiagram">
            <h3 className="alTitle">
                Architecture <span>Diagram</span>
            </h3>
            {/*<ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="pills-tech-tab"
            data-toggle="pill"
            href="#pills-tech"
            role="tab"
            aria-controls="pills-tech"
            aria-selected="true"
          >
            Technology
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-product-tab"
            data-toggle="pill"
            href="#pills-product"
            role="tab"
            aria-controls="pills-product"
            aria-selected="false"
          >
            Product
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-code-tab"
            data-toggle="pill"
            href="#pills-code"
            role="tab"
            aria-controls="pills-code"
            aria-selected="false"
          >
            Code
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            className="nav-link"
            id="pills-db-tab"
            data-toggle="pill"
            href="#pills-db"
            role="tab"
            aria-controls="pills-db"
            aria-selected="false"
          >
            Database Schema
          </a>
        </li>
      </ul>
      */}
            <Navbar collapseOnSelect expand="lg" bg="white" sticky="top">
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto justify-content-center" defaultActiveKey="#pills-tech">
                        <Nav.Link
                            className={'architecture-menu'}
                            href="#pills-tech"
                            onClick={() => setToggleClass(1)}
                        >
                            Technology
                        </Nav.Link>
                        {isAuthenticated ? (
                            <>
                                <Nav.Link
                                    className="architecture-menu"
                                    href="#pills-product"
                                    onClick={() => setToggleClass(2)}
                                >
                                    Product
                                </Nav.Link>
                                <Nav.Link
                                    className="architecture-menu"
                                    href="#pills-code"
                                    onClick={() => setToggleClass(3)}
                                >
                                    Code
                                </Nav.Link>
                                <Nav.Link
                                    className="architecture-menu"
                                    href="#pills-db"
                                    onClick={() => setToggleClass(4)}
                                >
                                    Database Schema
                                </Nav.Link>
                            </>
                        ) : null}
                    </Nav>
                </Navbar.Collapse>
            </Navbar>

            <div className="tab-content" id="pills-tabContent">
                <div
                    className={toggleClass === 1 ? 'tab-pane fade show active' : 'none'}
                    id="pills-tech"
                    role="tabpanel"
                    aria-labelledby="pills-tech-tab"
                >
                    <img
                        className="w-100"
                        src="/assets/images/Technology_Architecture.png"
                        alt=""
                    />
                </div>
                {isAuthenticated ? (
                    <>
                        <div
                            className={toggleClass === 2 ? 'tab-pane fade show active' : 'none'}
                            id="pills-product"
                            role="tabpanel"
                            aria-labelledby="pills-product-tab"
                        >
                            <img
                                className="w-100"
                                src="/assets/images/Product_Architecture.png"
                                alt=""
                            />
                        </div>
                        <div
                            className={toggleClass === 3 ? 'tab-pane fade show active' : 'none'}
                            id="pills-code"
                            role="tabpanel"
                            aria-labelledby="pills-code-tab"
                        >
                            <img
                                className="w-100"
                                src="/assets/images/Code_Architecture.png"
                                alt=""
                            />
                        </div>
                        <div
                            className={toggleClass === 4 ? 'tab-pane fade show active' : 'none'}
                            id="pills-db"
                            role="tabpanel"
                            aria-labelledby="pills-db-tab"
                        >
                            <img className="w-100" src="/assets/images/ER_Diagram.png" alt="" />
                        </div>
                    </>
                ) : null}
            </div>

            {/* <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Row>
          <Col sm={3}>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="first">Tab 1</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="second">Tab 2</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <h1>data</h1> 
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <h2>data</h2>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container> */}
        </div>
    )
}
export default Architecture
