import React, { useState, useContext, useEffect } from 'react'

import AuthContext from '../../../../context/auth/authContext'
import AlertContext from '../../../context/alert/alertContext'
import ApiManageContext from '../../../../context/apiManage/apiManageContext'

const HeadAlert = () => {
    const authContext = useContext(AuthContext)
    const alertContext = useContext(AlertContext)
    const apiManageContext = useContext(ApiManageContext)

    const { setAlert } = alertContext
    const { responseStatus: responseStatusAuth, clearResponse: clearResponseAuth } = authContext
    const {
        addApi,
        responseStatus: responseStatusAPi,
        clearResponse: clearResponseApi,
    } = apiManageContext

    useEffect(() => {
        if (responseStatusAuth) {
            if (!responseStatusAuth.noAlert) {
                setAlert(responseStatusAuth.message, responseStatusAuth.status)
            }
            clearResponseAuth()
        }
    }, [responseStatusAuth])

    useEffect(() => {
        if (responseStatusAPi) {
            setAlert(responseStatusAPi.message, responseStatusAPi.status)
            clearResponseApi()
        }
    }, [responseStatusAPi])

    return <></>
}
export default HeadAlert
