import { GET_ALL_VERESION, CLEAR_RESPONSE, RESPONSE_STATUS, GET_BIT_VERESION } from './versionType'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_ALL_VERESION:
            return {
                ...state,
                all_version: action.payload,
            }
        case GET_BIT_VERESION:
            return {
                ...state,
                all_bit_version: action.payload,
            }
        default:
            return state
    }
}
