import logo from './logo.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import React from 'react'
import Router from './routes/index'
import { BrowserRouter } from 'react-router-dom'
import UserState from './context/user/userState'
import { setAuthToken } from './common/api'
import VersionState from './context/version/versionState'
import AlertState from './common/context/alert/alertState'
import SettingState from './common/context/setting/settingState'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import CommonState from './common/context/common/commonState'
import AuthState from './context/auth/authState'
import EmployeeState from './context/employee/empState'
import HeadAlert from './common/components/molecules/HeadAlert'
import ApiManageState from './context/apiManage/apiManageState'

global.site_url = process.env.REACT_APP_DOMAIN
global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'AE'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}
function App() {
    return (
        <AuthState>
            <UserState>
                <VersionState>
                    <BrowserRouter>
                        <SettingState>
                            <AlertState>
                                <EmployeeState>
                                    <ApiManageState>
                                        <CommonState>
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <HeadAlert />
                                                <Router />
                                            </MuiPickersUtilsProvider>
                                        </CommonState>
                                    </ApiManageState>
                                </EmployeeState>
                            </AlertState>
                        </SettingState>
                    </BrowserRouter>
                </VersionState>
            </UserState>
        </AuthState>
    )
}

export default App
