import React, { useReducer } from 'react'
import SettingContext from './settingContext'
import SettingReducer from './settingReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_STATIC_PAGES,
    GET_ALL_TABLE_VALUES,
    GET_SINGLE_STATIC_PAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_BID_INCREMENTS,
    GET_SINGLE_BID_INCREMENT,
} from './settingTypes'

const SettingState = (props) => {
    const initialState = {
        configurations: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_configuration: {
            record: {},
        },
        bid_increments: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_bid_increment: {
            record: {},
        },
        table_data: null,
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(SettingReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllConfigurations = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'configurationVariables', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_STATIC_PAGES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleConfiguration = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleConfiguration', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_STATIC_PAGE,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const configurationAction = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'configurationAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchTable = async (formData, fromValue) => {
        const from = fromValue || 'gettableDetalis'
        try {
            // /custom/search
            const [res] = await Promise.all([
                // method, url, data, headertype, baseurl, common, custom, front
                apiCall('post', 'search', formData, '', 'custom', false, false, true),
            ])
            console.log(res, 'response')
            dispatch({
                type: GET_ALL_TABLE_VALUES,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                    setDisp: res.data.data.responseData.setDisp,
                    from: from,
                },
            })
        } catch (err) {
            dispatch({
                type: GET_ALL_TABLE_VALUES,
                payload: 'Something went wrong!',
                from,
            })
        }
    }

    const getAllBidIncrements = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAllBidIncrements', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_INCREMENTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleBidIncrement = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleBidIncrement', formData, '', 'setting'),
            ])
            const from = 'CommunicationStaticPages'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_BID_INCREMENT,
                    payload: {
                        record: res.data.data.responseData.item
                            ? res.data.data.responseData.item
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bidIncrementAction = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'bidIncrementAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const deleteBidIncrement = async (formData) => {
        try {
            const from = 'CommunicationStaticPages'
            const [res] = await Promise.all([
                apiCall('post', 'deleteBidIncrement', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchTableAction = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'CommunicationSearchTable'
            //apiCall("post", "searchTableAction", formData, "", "setting"),

            const [res] = await Promise.all([
                apiCall('post', 'action', formData, '', 'custom', false, false, true),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const multiTableAction = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'multiTableAction'
            const [res] = await Promise.all([
                apiCall('post', 'multiTableAction', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const searchTableCommon = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'SearchTable'
            const [res] = await Promise.all([
                apiCall('post', 'searchTable', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const dataInsertAction = async (formData, from = 'dataInsertAction') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'datatable_insert', formData, '', 'setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const dataUpdateAction = async (formData, from = 'dataUpdateAction') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'datatable_update', formData, '', 'admin/setting'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <SettingContext.Provider
            value={{
                responseStatus: state.responseStatus,
                single_configuration: state.single_configuration,
                configurations: state.configurations,
                bid_increments: state.bid_increments,
                single_bid_increment: state.single_bid_increment,
                table_data: state.table_data,
                clearResponse,
                getAllConfigurations,
                getSingleConfiguration,
                configurationAction,
                getAllBidIncrements,
                getSingleBidIncrement,
                bidIncrementAction,
                deleteBidIncrement,
                searchTableAction,
                dataInsertAction,
                dataUpdateAction,
                searchTable,
                searchTableCommon,
                multiTableAction,
            }}
        >
            {props.children}
        </SettingContext.Provider>
    )
}

export default SettingState
