import React, { useContext, useEffect } from 'react'
import TrainDoc from '../views/front/trainDoc/index'
import Login from '../views/admin/login/index'
import Header from '../views/common/header/index'
import { Route, Switch } from 'react-router-dom'
import Layout from '../views/admin/pannel'
import BitListVersion from '../views/admin/bitVersion/index'
import ListVersion from '../views/admin/version/index'
import Versionhist from '../views/front/version/versionhist'
import Home from '../views/front/home/index'
import Status from '../views/front/status'
import PrivateRoute from './privateRoute'
import AuthContext from '../context/auth/authContext'
import EmployeeView from '@/views/admin/EmployeeManagement'
import ProjectList from '@/views/admin/Settings/projectList'
import ApiList from '@/views/admin/Settings/apiList'
// import Swagger from '../views/front/swagger/index'

function Router() {
    const { loadUser } = useContext(AuthContext)
    // const { getAllCountries, getAllStates } = useContext(ProductCommonContext)
    // const { getGlobalVariable } = useContext(CustomCommonContext)

    useEffect(() => {
        if (localStorage.token) {
            loadUser()
        }
        // getGlobalVariable()
        // getAllCountries()
        // getAllStates()
    }, [])

    return (
        <>
            <Header />
            <Switch>
                <Route path="/" component={Header}>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/version" component={Versionhist} />
                    <Route exact path="/status" component={Status} />
                    <Route exact path="/train/:doc" component={TrainDoc} />
                    <PrivateRoute exact path="/admin/bitversion" component={BitListVersion} />
                    <Route exact path="/employees/all" component={EmployeeView} />
                    <Route exact path="/admin/version" component={ListVersion} />
                    <Route exact path="/testpannel" component={Layout} />
                    <Route exact path="/admin/apiList" component={ApiList} />
                    <PrivateRoute exact path="/admin/projects" component={ProjectList} />
                    {/* <Route exact path="/swagger" component={Swagger} /> */}
                </Route>
            </Switch>
        </>
    )
}
export default Router
