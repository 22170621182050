import React, { useContext, useState, useEffect } from 'react'
import { Button, IconButton } from '@material-ui/core'
import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import CustomDialog from '../../../common/components/organisms/Dialog'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SettingContext from '../../../common/context/setting/settingContext'
import { dateTimeFormatFunction } from '../../../common/common/components'
import ApiManageContext from '../../../context/apiManage/apiManageContext'
const ApiListManage = (props) => {
    const settingContext = useContext(SettingContext)
    const apiManageContext = useContext(ApiManageContext)
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingContext

    const { addApi, responseStatus, clearResponse } = apiManageContext

    const [product, setProduct] = useState(null)

    const data = props.data
    const toggleFullScreenPopup = props.function
    let siteTypes = props.siteTypes

    const validationArray = Yup.object({
        addtionalAPI: Yup.string().required('Required!'),
        api_type: Yup.string().required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            addtionalAPI: '',
            api_type: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            addApi(values)
        },
    })

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            //getSingleConfiguration(formik.values);
            searchTable(
                {
                    limit: 1,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {
                        id: {
                            value: data.id,
                            type: 'in',
                            field: 'ap.id',
                        },
                    },
                    table: 'api_list',
                },
                'editApi',
            )
        } else if (data.status === 'new') {
            resetFrom()
        }
        return () => {
            resetFrom()
            clearResponseSetting()
        }
    }, [data.status])

    useEffect(() => {
        console.log(props.data, 'dddddd')
    }, [props.data])

    useEffect(() => {
        console.log(table_data, 'tabbbbb')
        if (table_data && table_data.from === 'editApi' && data.status === 'edit') {
            console.log(table_data, 'editApi')
            const record = table_data.records[0]
            formik.setFieldValue('all_apis', record.all_apis)
            formik.setFieldValue('site_type', record.site_type)
        }
    }, [table_data])
    /*useEffect(() => {
    if (single_configuration.record && data.status === "edit") {
      const template = single_configuration.record;

      setProduct(template);
      formik.values.id = template.id;
      formik.values.type = template.type;
      formik.values.question = template.question;
      formik.values.variable = template.variable;

      if (template.type === "checkbox") {
        formik.setFieldValue("value", template.value === "1" ? true : false);
      } else {
        formik.setFieldValue("value", template.value);
      }
    } else {
      resetFrom();
    }
  }, [single_configuration]);*/
    useEffect(() => {
        if (
            responseStatusSetting &&
            responseStatusSetting.status === 'success' &&
            responseStatusSetting.from === 'CommunicationSearchTable'
        ) {
            searchTable(
                {
                    limit: 20,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {},
                    table: 'api_list',
                },
                'editApi',
            )
            toggleFullScreenPopup(false)
        }
        return () => {
            clearResponseSetting()
        }
    }, [responseStatusSetting])

    useEffect(() => {
        if (responseStatus && responseStatus.status === 'success') {
            searchTable(
                {
                    limit: 20,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {},
                    table: 'api_list',
                },
                'editApi',
            )
            toggleFullScreenPopup(false)
        }
        return () => {
            resetFrom()
            clearResponse()
        }
    }, [responseStatus])
    console.log('template.value', formik.values.value)

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.all_apis = ''
        formik.values.site_type = ''

        /*formik.setFieldValue("id", 0);
    formik.setFieldValue("info", "");
    formik.setFieldValue("type", "");
    formik.setFieldValue("date", dateTimeFormatFunction(new Date()));
    formik.setFieldValue("enable", 1);
    formik.setFieldValue("version_doc", []);*/

        setProduct(null)
        formik.handleReset()
    }

    const emailTemplate = []

    /*if (data.status === "new") {
    emailTemplate.push(
      {
        label: "Variable",
        placeholder: "Enter Variable",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "variable",
        formik: formik,
      },
      {
        label: "Question",
        placeholder: "Enter Question",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "question",
        formik: formik,
      },
      {
        label: "Type",
        placeholder: "Enter type",
        class: "col-12 mb-4",
        type: "select",
        options: [
          { value: "checkbox", show: "Checkbox" },
          { value: "text", show: "Text" },
          { value: "number", show: "Number" },
        ],
        name: "type",
        formik: formik,
      }
    );
  }*/
    emailTemplate.push(
        {
            label: 'API End Point',
            placeholder: `Enter API End Point Url`,
            class: 'col-md-12',
            name: 'addtionalAPI',
            formik: formik,
        },

        {
            label: 'Site Type',
            placeholder: 'Enter type',
            class: 'col-md-12',
            type: 'select',
            options: siteTypes.map((item) => {
                let valueChanged = {}
                valueChanged.show = item
                valueChanged.value = item
                return valueChanged
            }),
            name: 'api_type',
            formik: formik,
        },
    )

    return (
        <CustomDialog
            title={
                <div className="d-flex align-items-center justify-content-between">
                    Add New API
                    <IconButton onClick={() => toggleFullScreenPopup(false)}>
                        <span className="material-icons">clear</span>
                    </IconButton>
                </div>
            }
            open={data.popup}
            className="ConfirmModal"
            function={() => toggleFullScreenPopup(false)}
        >
            <>
                {/* <h5>{'You are about to pl  ace a bid'}!</h5>
                <h5>Please confirm if you want to continue with this action</h5> */}
                <form>
                    <div className="row mb-4">{Object.values(mapData(emailTemplate))}</div>
                    <div className="actionWrapper">
                        <PrimaryButton
                            onClick={formik.handleSubmit}
                            id="confirm_bid_submit"
                            type="button"
                            disabled={formik.isSubmitting}
                            label={'Submit'}
                        />
                    </div>
                </form>
            </>
        </CustomDialog>
        // <FullScreenPopup
        //     modaltitle={data.status === 'new' ? 'ADD API' : 'EDIT API'}
        //     open={data.popup}
        //     handleClose={() => toggleFullScreenPopup(false)}
        // >
        //     <div className="addUserModal">
        //         <div className="fspBody">
        //             <form onSubmit={formik.handleSubmit} autoComplete="nofill">
        //                 {(data.status === 'edit' || data.status === 'new') && (
        //                     <div className="row">{Object.values(mapData(emailTemplate))}</div>
        //                 )}
        //                 <div className="actionButton d-flex justify-content-center align-items-center">
        //                     <SecondaryButton
        //                         label="Cancel"
        //                         onClick={() => toggleFullScreenPopup(false)}
        //                     />
        //                     <PrimaryButton type="submit" label="Submit" />
        //                 </div>
        //             </form>
        //         </div>
        //     </div>
        // </FullScreenPopup>
    )
}

export default ApiListManage
