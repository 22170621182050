import React, { useReducer } from 'react'
import VersionContext from './versionContext'
import versionReducer from './versionReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { GET_ALL_VERESION, GET_BIT_VERESION, CLEAR_RESPONSE, RESPONSE_STATUS } from './versionType'
const VersionState = (props) => {
    const initialState = {
        responseStatus: null,
        all_version: [],
        all_bit_version: [],
    }
    const [state, dispatch] = useReducer(versionReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)
    const getAllVersions = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'showVersions', formData, '', 'version'),
            ])
            dispatch({
                type: GET_ALL_VERESION,
                payload: res.data.data.responseData ? res.data.data.responseData : [],
            })
        } catch (err) {
            resp.commonErrorResponse('showVersions', noAlert)
        }
    }
    const updateVersion = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateVersion', formData, '', 'version'),
            ])
            resp.commonResponse(res.data, 'updateVersion', noAlert)
        } catch (err) {
            resp.commonErrorResponse('updateVersion', noAlert)
        }
    }
    const createVersion = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addVersion', formData, '', 'version'),
            ])
            resp.commonResponse(res.data, 'addVersion', noAlert)
        } catch (err) {
            resp.commonErrorResponse('addVersion', noAlert)
        }
    }
    const updateBitVersion = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'updateBitVersion', formData, '', 'version'),
            ])
            resp.commonResponse(res.data, 'updateBitVersion', noAlert)
        } catch (err) {
            resp.commonErrorResponse('updateBitVersion', noAlert)
        }
    }
    const getBitVersion = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'showBitVersion', formData, '', 'version'),
            ])

            dispatch({
                type: GET_BIT_VERESION,
                payload: res.data.data.responseData ? res.data.data.responseData : [],
            })
            resp.commonResponse(res.data, 'getBitVersion', noAlert)
        } catch (err) {
            resp.commonErrorResponse('getBitVersion', noAlert)
        }
    }
    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <VersionContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_version: state.all_version,
                all_bit_version: state.all_bit_version,
                getAllVersions,
                updateVersion,
                createVersion,
                updateBitVersion,
                getBitVersion,
                clearResponse,
            }}
        >
            {props.children}
        </VersionContext.Provider>
    )
}

export default VersionState
