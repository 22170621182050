import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import CheckBox from '../../../common/components/atoms/CheckBox'
import { Link } from 'react-router-dom'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../../context/auth/authContext'
import Loaders from '../../../common/components/molecules/Loaders'
import './style.css'
const Login = () => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(true)

    const { login, responseStatus, clearResponse, isAuthenticated, loadUser } = authContext

    useEffect(() => {
        setIsLoading(false)
        if (isAuthenticated) {
            handleRedirectInternal(history, 'dashboard')
        }
    }, [isAuthenticated])

    const validationArray = Yup.object({
        email: Yup.string().email('Invalid email format').required('Required!'),
        password: Yup.string().min(8, 'Minimum 8 characters').required('Required!'),
    })

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            login(values)
        },
    })

    const loginInfo = [
        {
            label: 'Email address',
            name: 'email',
            type: 'email',
            placeholder: 'Enter your email address',
            class: 'col-12',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Password',
            name: 'password',
            type: 'password',
            placeholder: 'Enter your password',
            class: 'col-12',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatus) {
            if (responseStatus.from === 'login') {
                if (responseStatus.status === 'success') {
                    handleRedirectInternal(history, 'admin/version')
                }
            }
            setIsLoading(false)
        }
    }, [responseStatus])
    return (
        <div className="center mnLgnVwWrpr container">
            {isLoading ? (
                <>
                    <Loaders name="banner" isLoading={isLoading} />
                </>
            ) : (
                <div className="loginCnt">
                    <form onSubmit={formik.handleSubmit}>
                        <div>
                            <div className="col-12 loginTitle">
                                <h2>Welcome to Large Auction Software</h2>
                            </div>
                            <div className="row">{Object.values(mapData(loginInfo))}</div>
                            <div className="fpCnt col-12 d-flex justify-content-between align-items-center mb-2">
                                <CheckBox label="Remember me" />
                                {/* <Link to="#">Forgot Password ?</Link> */}
                            </div>
                            <div className="col-12">
                                <PrimaryButton label="Login" type="submit" btnSize="large" />
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}

export default Login
