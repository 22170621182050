import React, { useReducer } from 'react'
import EmployeeContext from './empContext'
import EmployeeReducer from './empReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_ALL_EMPLOYEES, GET_SINGLE_EMPLOYEE, CLEAR_RESPONSE, RESPONSE_STATUS } from './empTypes'

const EmployeeState = (props) => {
    const initialState = {
        responseStatus: null,
        single_employee: {
            record: {},
        },
        all_employees: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(EmployeeReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllEmployees = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allemployees', formData, '', 'employee'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_EMPLOYEES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleEmployee = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleEmployee', formData, '', 'employee'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_EMPLOYEE,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const employeeAction = async (formData) => {
        try {
            const from = 'employeeAction'
            const [res] = await Promise.all([
                apiCall('post', 'employeeAction', formData, '', 'employee'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeUserStatus = async (formData) => {
        try {
            const from = 'changeStatus'
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'employee'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <EmployeeContext.Provider
            value={{
                all_employees: state.all_employees,
                single_employee: state.single_employee,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllEmployees,
                getSingleEmployee,
                employeeAction,
                changeUserStatus,
            }}
        >
            {props.children}
        </EmployeeContext.Provider>
    )
}

export default EmployeeState
