import React, { useEffect, useContext, useState } from 'react'
import { DropdownButton, Dropdown, NavDropdown } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import authContext from '../../context/auth/authContext'

function CustomContainer() {
    const { isAuthenticated, user, logout } = useContext(authContext)

    let history = useHistory()

    return (
        <div className="alBanner customContainer">
            <div className="albMain">
                <div className="albLt">
                    <h6 className="text-uppercase">AuctionSoftware</h6>
                    <h2>LARGE</h2>
                    <h6 className="text-uppercase">Easier way to do bigger things</h6>
                    <div className="actBtn">
                        {isAuthenticated ? (
                            <>
                                {' '}
                                <Link to="/docs/index.html" target="_blank" className="btn cstmBtn">
                                    Code Documentation
                                </Link>
                                <NavDropdown
                                    title="API Documentation"
                                    id="basic-nav-dropdown"
                                    className="btn cstmBtn"
                                >
                                    <NavDropdown.Item
                                        onClick={() =>
                                            window.open(
                                                'https://documenter.getpostman.com/view/7222650/2sAXxWZ8mN',
                                                '_blank',
                                            )
                                        }
                                    >
                                        Postman
                                    </NavDropdown.Item>
                                    <NavDropdown.Item onClick={() => history.push('/swagger')}>
                                        Swagger
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </>
                        ) : null}

                        <a href="#architectureDiagram" className="btn cstmBtn">
                            Architecture Diagram{' '}
                        </a>
                        <a href="#versionHistory" className="btn cstmBtn">
                            Version History
                        </a>
                        {/*<a
              href={`${process.env.REACT_APP_DOMAIN}/docs/FlowDocument.pdf`}
              target="_blank"
              className="btn cstmBtn"
            >
              Flow Documentation
            </a>*/}
                        {isAuthenticated ? (
                            <NavDropdown
                                title="Dev Documentation"
                                id="basic-nav-dropdown"
                                className="btn cstmBtn"
                            >
                                <NavDropdown.Item onClick={() => history.push('/train/database')}>
                                    DataBase
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => history.push('/train/frontend')}>
                                    Frontend
                                </NavDropdown.Item>
                                <NavDropdown.Item onClick={() => history.push('/train/backend')}>
                                    Backend
                                </NavDropdown.Item>
                            </NavDropdown>
                        ) : null}
                        {isAuthenticated ? (
                            <NavDropdown
                                title="Other Documents"
                                id="basic-nav-dropdown"
                                className="btn cstmBtn"
                            >
                                <NavDropdown.Item
                                    onClick={() =>
                                        window.open(
                                            `${process.env.REACT_APP_DOMAIN}/docs/FlowDocument.pdf`,
                                            '_blank',
                                        )
                                    }
                                >
                                    Flow Documentation
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() =>
                                        window.open(
                                            `${process.env.REACT_APP_DOMAIN}/docs/Feature_Document_Large.pdf`,
                                            '_blank',
                                        )
                                    }
                                >
                                    Feature Documentation
                                </NavDropdown.Item>
                                <NavDropdown.Item
                                    onClick={() =>
                                        window.open(
                                            `${process.env.REACT_APP_DOMAIN}/docs/Postman_Procedure.pdf`,
                                            '_blank',
                                        )
                                    }
                                >
                                    Postman Procedure Documentation
                                </NavDropdown.Item>
                            </NavDropdown>
                        ) : null}
                    </div>
                </div>
                <div className="albRt">
                    <img src="/assets/images/illustration.png" alt="" />
                </div>
            </div>
        </div>
    )
}
export default CustomContainer
