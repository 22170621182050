import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SettingContext from '../../../common/context/setting/settingContext'
import { dateTimeFormatFunction } from '../../../common/common/components'
const TemplateManage = (props) => {
    const settingContext = useContext(SettingContext)

    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingContext

    const [product, setProduct] = useState(null)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        info: Yup.string('Please enter valid Date').required('Required!'),
        type: Yup.string('Please enter valid data!').required('Required!'),
        date: Yup.date('Please enter valid date!').nullable().required('Required!'),
    })
    const formik = useFormik({
        initialValues: {
            id: 0,
            info: '',
            type: '',
            date: '',
            enable: 0,
            version_doc: [],
            table: 'version_info',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            searchTableAction(values)
        },
    })

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            //getSingleConfiguration(formik.values);
            searchTable(
                {
                    limit: 1,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {
                        id: {
                            value: data.id,
                            type: 'in',
                            field: 'vi.id',
                        },
                    },
                    table: 'version_info',
                },
                'editVersion',
            )
        } else if (data.status === 'new') {
            resetFrom()
        }
        return () => {
            resetFrom()
            clearResponseSetting()
        }
    }, [data.status])
    useEffect(() => {
        if (table_data && table_data.from === 'editVersion' && data.status === 'edit') {
            console.log(table_data, 'editVersion')
            const record = table_data.records[0]
            formik.setFieldValue('id', record.id)
            formik.setFieldValue('info', record.info)
            formik.setFieldValue('type', record.type)
            formik.setFieldValue('date', record.date)
            formik.setFieldValue('enable', record.enable)
            formik.setFieldValue(
                'version_doc',
                record.version_doc ? record.version_doc.split(',') : [],
            )
        }
    }, [table_data])
    /*useEffect(() => {
    if (single_configuration.record && data.status === "edit") {
      const template = single_configuration.record;

      setProduct(template);
      formik.values.id = template.id;
      formik.values.type = template.type;
      formik.values.question = template.question;
      formik.values.variable = template.variable;

      if (template.type === "checkbox") {
        formik.setFieldValue("value", template.value === "1" ? true : false);
      } else {
        formik.setFieldValue("value", template.value);
      }
    } else {
      resetFrom();
    }
  }, [single_configuration]);*/
    useEffect(() => {
        console.log(responseStatusSetting, 'responseStatusSetting')
        if (
            responseStatusSetting &&
            responseStatusSetting.status === 'success' &&
            responseStatusSetting.from === 'CommunicationSearchTable'
        ) {
            searchTable(
                {
                    limit: 20,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {},
                    table: 'version_info',
                },
                'editVersion',
            )
            toggleFullScreenPopup(false)
        }
        return () => {
            clearResponseSetting()
        }
    }, [responseStatusSetting])
    console.log('template.value', formik.values.value)

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.info = ''
        formik.values.type = ''
        formik.values.date = dateTimeFormatFunction(new Date())
        formik.values.enable = 1
        formik.values.version_doc = []

        /*formik.setFieldValue("id", 0);
    formik.setFieldValue("info", "");
    formik.setFieldValue("type", "");
    formik.setFieldValue("date", dateTimeFormatFunction(new Date()));
    formik.setFieldValue("enable", 1);
    formik.setFieldValue("version_doc", []);*/

        setProduct(null)
        formik.handleReset()
    }

    const emailTemplate = []

    /*if (data.status === "new") {
    emailTemplate.push(
      {
        label: "Variable",
        placeholder: "Enter Variable",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "variable",
        formik: formik,
      },
      {
        label: "Question",
        placeholder: "Enter Question",
        class: "col-12 mb-4",
        type: "text",
        shrink: true,
        name: "question",
        formik: formik,
      },
      {
        label: "Type",
        placeholder: "Enter type",
        class: "col-12 mb-4",
        type: "select",
        options: [
          { value: "checkbox", show: "Checkbox" },
          { value: "text", show: "Text" },
          { value: "number", show: "Number" },
        ],
        name: "type",
        formik: formik,
      }
    );
  }*/
    emailTemplate.push(
        {
            label: 'Type',
            placeholder: 'Enter type',
            class: 'col-6 mb-4',
            type: 'select',
            options: [
                { value: 'feature', show: 'Feature' },
                { value: 'bug', show: 'Bug' },
            ],
            name: 'type',
            formik: formik,
        },
        {
            label: 'Date *',
            placeholder: `Enter ${'Value'}`,
            class: 'col-3 mt-3',
            name: 'date',
            type: 'month',
            formik: formik,
        },
        {
            label: 'Enable',
            placeholder: 'Make Active',
            type: 'switch',
            class: 'col-3 mt-4',
            name: 'enable',
            formik: formik,
        },
        {
            label: 'Value *',
            placeholder: `Enter ${'Value'}`,
            class: 'col-12',
            name: 'info',
            type: 'ckeditor',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            name: 'version_doc',
            icon: 'description',
            innerText: 'upload version Document',
            class: 'col-12',
            folder: 'docs',
            multiple: true,
            accept: 'image/*, application/*',
        },
    )

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new' ? 'ADD COMMENT' : `EDIT COMMENT: ${formik.values.date}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {((formik.values.info && data.status === 'edit') ||
                            data.status === 'new') && (
                            <div className="row">{Object.values(mapData(emailTemplate))}</div>
                        )}
                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
