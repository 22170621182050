import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../../common/components/templates/Layout'
import CustomTable from '../../../common/components/molecules/CustomTable'
import CustomSearch from '../../../common/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
//import { handleRedirectInternal } from "./../../../common/components";
import { useFormik } from 'formik'
//import CommunicationContext from "../../product/context/communication/communicationContext";
import Loaders from '../../../common/components/molecules/Loaders/index'
import SettingContext from '../../../common/context/setting/settingContext'
import AlertContext from '../../../common/context/alert/alertContext'
import { handleRedirectInternal } from '../../../common/common'
import * as Yup from 'yup'
import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData } from '../../../common/common/components'
import ProjectListManage from './projectListManage'
const ProjectList = (props) => {
    const alertContext = useContext(AlertContext)
    //const communicationContext = useContext(CommunicationContext);
    const settingcontext = useContext(SettingContext)

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { setAlert } = alertContext

    /*const {
    all_templates,
    getAllSearchTemplates,
    responseStatus: responseStatusCommuncation,
    clearResponse: clearResponseCommuncation,
  } = communicationContext; */
    console.log(settingcontext, 'settingContext')
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingcontext
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        action: '',
    })

    const onClickEdit = (id, secondaryID, type) => {
        /*if (type === "bidcount") {
      setBidHistoryValue(id);
    } else {*/
        toggleFullScreenPopup(true, type, id)
        //}
    }
    let action = props.match.params.action

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id, action: action })
        } else {
            setManage({ popup, status: 'new', id: 0, action: action })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {},
            table: 'projects',
        },
        onSubmit: (values) => {
            //values.filters.name.value = values.name;
            setIsLoading(true)
            searchTable(values, 'projectList')
        },
    })
    useEffect(() => {
        formik.handleSubmit()
        return () => {
            //formik.values.filters.name.value = "";
        }
    }, [])
    useEffect(() => {
        formik.handleSubmit()
    }, [manage.popup])
    useEffect(() => {
        console.log(table_data, 'dbdt')
        if (
            table_data &&
            (table_data.from === 'projectList' || 'gettableDetalis') &&
            table_data.records
        ) {
            setTableBody(table_data.records.length ? table_data.records : [])
            setIsLoading(false)
        }
    }, [table_data])
    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.action = action
        formik.values.page = 1
        formik.values.searchterm = ''
        //getAllSearchTemplates(formik.values)
        searchTable(formik.values, 'projectList')
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Id',
        },
        {
            field: 'name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Project Name',
        },
        {
            field: 'enable',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            click: true,
            onclick: onClickEdit,
            clickID: 'id',
            secondaryClickID: 'comment',
            clickType: 'edit',
            type: 'button',
            sort: true,
            sortField: 'action',
            firstChild: false,
            disablePadding: false,
            label: 'Edit',
        },
    ]

    const searchInfo = [
        {
            label: 'Project Name',
            placeholder: 'Enter Project Name',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'name',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard communications">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <Button
                    variant="outlined"
                    className="btnOutlined mr-2"
                    onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                >
                    <span className="material-icons">note_add</span>Add Project
                </Button>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={table_data}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={searchTable}
                />
                <ProjectListManage data={manage} function={toggleFullScreenPopup} />
            </div>
        </Layout>
    )
}
export default ProjectList
