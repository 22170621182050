import React, { useEffect, useContext, useState } from 'react'
import './header.css'
import { Link, useHistory, useLocation } from 'react-router-dom'
import authContext from '../../../context/auth/authContext'
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap'
import { handleRedirectInternal } from '../../../common/common'
import { setAuthToken } from '../../../common/api'
import AlertContext from '../../../common/context/alert/alertContext'

function Header() {
    const { isAuthenticated, user, logout } = useContext(authContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext

    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        console.log(user, '@user')
    }, [user])

    console.log('isAuthenticated', isAuthenticated)
    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }
    /**
 *    <Button variant="primary" onClick={handleShow}>
          Launch demo modal
        </Button>
          const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 */
    return (
        <>
            <Navbar
                bg="light"
                variant="light"
                className={`mnHdrNavCntnr ${location.pathname === '/' ? 'fdNav' : null}`}
            >
                <div className="customContainer d-flex align-items-center justify-content-between">
                    <a href="https://www.auctionsoftware.com/" target="_blank" rel="noreferrer">
                        <div className="logoCnt">
                            <img src="/assets/images/aslogo.png" alt="" />
                        </div>
                    </a>
                    <div className="rtNavCntr d-flex align-items-center justify-content-end">
                        <Nav>
                            <Nav.Link>
                                <Link to="/" className="main-menu">
                                    Home
                                </Link>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link>
                                <Link to="/status" className="main-menu">
                                    Status
                                </Link>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link>
                                <Link to="/version" className="main-menu">
                                    Version History
                                </Link>
                            </Nav.Link>
                        </Nav>
                        <Nav>
                            <a
                                href="https://largeproductadmin-test.ecommerce.auction/"
                                target="_blank"
                                className="main-menu"
                                rel="noreferrer"
                            >
                                Demo Admin
                            </a>
                        </Nav>

                        <Nav>
                            <a
                                href="https://largeproduct-test.ecommerce.auction/"
                                target="_blank"
                                className="main-menu"
                                rel="noreferrer"
                            >
                                Demo Front
                            </a>
                        </Nav>
                        {isAuthenticated ? (
                            <>
                                <Nav.Link>
                                    <NavDropdown title={`Documents`} id="basic-nav-dropdown">
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/FlowDocument.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Flow Documentation
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/TRAINING.pptx`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Training Documentation
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Feature_Document_Large.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Feature Documentation
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Postman_Procedure.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Postman Procedure Documentation
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Initial_Setup_and_DB_Documentation.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Initial Setup and DB Documentation
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Dispute_Flow_Document.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Dispute Flow Document
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/ERP_DIFFERENCES.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Difference Between ERP in Auction IO and Large
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/CONFIGURATION_FEATURES.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Configuration Features
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/THIRD_PARTY.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Third Party Variable
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Link>
                                <Nav.Link>
                                    <NavDropdown
                                        title={`Project Documents`}
                                        id="basic-nav-dropdown"
                                    >
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/The_Chicago_Wine.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            The Chicago Wine
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Primis_Medical.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Primis Medical
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Debeers_offer_and_bulk_upload_functionality.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            De Beers
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/NPS_feature_document.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            NPS
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/NPS_functional _design_docs.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            NPS - Feature Flow
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Brilliant_User_Story_Document.docx`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Brilliant User Story
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Ekkanoo_User_Flow_Document.pdf`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Ekkanoo User Flow
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/IQuippo_Feature_Document.docx`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Iquippo Feature Document
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Iquippo_User_Story.docx`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Iquippo User Story
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item
                                            onClick={() =>
                                                window.open(
                                                    `${process.env.REACT_APP_DOMAIN}/docs/Minnesota_User_Story.docx`,
                                                    '_blank',
                                                )
                                            }
                                        >
                                            Minnesota User Story
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Link>
                            </>
                        ) : null}
                        <Nav>
                            {isAuthenticated ? (
                                <Nav.Link>
                                    <NavDropdown title={`Hello, Admin`} id="basic-nav-dropdown">
                                        {isAuthenticated && (
                                            <NavDropdown.Item
                                                onClick={() =>
                                                    handleRedirectInternal(history, 'admin/version')
                                                }
                                            >
                                                Admin Panel
                                            </NavDropdown.Item>
                                        )}
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item onClick={() => onLogOut()}>
                                            Logout
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                </Nav.Link>
                            ) : (
                                <Nav.Link>
                                    <Link to="/login" className="main-menu">
                                        Login
                                    </Link>
                                </Nav.Link>
                            )}
                        </Nav>
                    </div>
                </div>
            </Navbar>
        </>
    )
}
export default Header
