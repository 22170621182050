import React, { useEffect } from 'react'
import PrimaryButton from '../../atoms/PrimaryButton'

import './CustomSearch.css'
import { mapData } from '../../../common/components'

const CustomSearch = (props) => {
    const searchInfo = props.searchInfo
    const formik = props.formik

    let condition = false
    if (searchInfo.length) {
        condition = true
    } else if (searchInfo.data) {
        if (searchInfo.data.length) {
            condition = true
        }
    }

    const handleSubmit = (e) => {
        formik.values.page = 1
        formik.handleSubmit(e)
    }
    return (
        <>
            {condition ? (
                <div className="commActBox d-flex justify-content-between align-items-center">
                    <div className="w-100">
                        <form
                            onSubmit={handleSubmit}
                            className="row flex-wrap d-flex justify-content-start align-items-center"
                        >
                            {mapData(searchInfo)}
                            <div className="col-auto ml-auto btnSearch">
                                <PrimaryButton label="SEARCH" type="submit" />
                            </div>
                        </form>
                    </div>
                </div>
            ) : null}
        </>
    )
}

export default CustomSearch
