import React, { useState, useEffect, useContext } from 'react'
import CommonContext from './../context/common/commonContext'
import { useDropzone } from 'react-dropzone'
import Loaders from '../components/molecules/Loaders'
import move from 'lodash-move'
import Reorder, {
    reorder,
    reorderImmutable,
    reorderFromTo,
    reorderFromToImmutable,
} from 'react-reorder'

const Uploader = (props) => {
    const [reload, setReload] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const commonContext = useContext(CommonContext)
    const { uploadImage, uploadedFiles, resetDropZone } = commonContext
    const formik = props.formik

    const auctionAvatar = useDropzone({
        accept: props.accept,
        multiple: props.multiple,
        onDrop: (acceptedFiles) => {
            setIsLoading(true)
            let arr = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                }),
            )
            var formData = new FormData()
            formData.append('folder', props.folder)
            formData.append('isCompress', props.isCompress)
            formData.append('quality', props.quality)
            formData.append('resultWidth', props.resultWidth)
            formData.append('resultHeight', props.resultHeight)

            arr.forEach((value, key) => {
                formData.append('file_upload', value)
            })
            uploadImage(formData, props.name)
        },
    })

    useEffect(() => {
        // console.log('uploadedFiles', props.name, uploadedFiles, formik)
        // console.log('props.name === uploadedFiles.from', props.name === uploadedFiles.from)
        if (props.name === uploadedFiles.from && uploadedFiles.files.length) {
            console.log('uploadedFiles', uploadedFiles)
            if (props.multiple) {
                let uploadedImage = []
                uploadedFiles.files &&
                    uploadedFiles.files.map((uploaded) => {
                        uploadedImage.push(uploaded.file_name)
                    })
                props.formik.setFieldValue(props.name, [
                    ...formik.values[props.name],
                    ...uploadedImage,
                ])
            } else {
                uploadedFiles.files &&
                    uploadedFiles.files.map((uploaded) =>
                        props.formik.setFieldValue(props.name, [uploaded.file_name]),
                    )
            }
            setIsLoading(false)
            setReload(!reload)
            resetDropZone(uploadedFiles.from)
        }
    }, [uploadedFiles])

    const getFileExtension = (file) => {
        let fileExtent = /[.]/.exec(file) ? /[^.]+$/.exec(file)[0] : undefined
        return fileExtent
    }

    const removeFile = (file, from, index) => {
        if (props.name === from) {
            const allFiles = formik.values[props.name]
            if (index > -1) {
                allFiles.splice(index, 1)
            }
            formik.setFieldValue(props.name, allFiles)
        }
    }

    const isValidHttpUrl = (string) => {
        let url

        try {
            url = new URL(string)
        } catch (_) {
            return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const viewFile = (file, from) => {
        if (props.name === from) {
            let newWindow = null
            if (isValidHttpUrl(file)) {
                newWindow = window.open(`${file}`, '_blank', 'noopener,noreferrer')
            } else {
                newWindow = window.open(
                    `${global.site_url}/uploads/${props.folder}/${file}`,
                    '_blank',
                    'noopener,noreferrer',
                )
            }

            if (newWindow) newWindow.opener = null
        }
    }

    const onReorder = (e, from, to) => {
        let changedArray = move(formik.values[props.name], from, to)
        formik.setFieldValue([props.name], changedArray)
    }

    return (
        <>
            <div>
                <section>
                    <div {...auctionAvatar.getRootProps({ className: 'dropzone' })}>
                        <input {...auctionAvatar.getInputProps()} />
                        <span className="material-icons">{props.icon}</span>
                        <h4>{props.titleText}</h4>
                        <h6>{props.innerText}</h6>
                    </div>
                    <p className="validationError text-left">
                        {formik.touched[props.name] &&
                            formik.errors[props.name] &&
                            formik.errors[props.name]}
                    </p>

                    <aside className="thumbsContainer">
                        <>
                            {isLoading ? (
                                <Loaders isLoading={isLoading} />
                            ) : formik.values[props.name] && formik.values[props.name].length ? (
                                <>
                                    {props.reorder ? (
                                        <Reorder
                                            reorderId={props.titleText} // Unique ID that is used internally to track this list (required)
                                            reorderGroup={props.titleText} // A group ID that allows items to be dragged between lists of the same group (optional)
                                            // getRef={this.storeRef.bind(this)} // Function that is passed a reference to the root node when mounted (optional)
                                            component="div" // Tag name or Component to be used for the wrapping element (optional), defaults to 'div'
                                            className="reorderCnt" // Class name to be applied to placeholder elements (optional), defaults to 'placeholder'
                                            // placeholderClassName="check" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                            draggedClassName="dragged" // Class name to be applied to dragged elements (optional), defaults to 'dragged'
                                            // lock="horizontal" // Lock the dragging direction (optional): vertical, horizontal (do not use with groups)
                                            // holdTime={500} // Default hold time before dragging begins (mouse & touch) (optional), defaults to 0
                                            touchHoldTime={250} // Hold time before dragging begins on touch devices (optional), defaults to holdTime
                                            mouseHoldTime={100} // Hold time before dragging begins with mouse (optional), defaults to holdTime
                                            onReorder={onReorder} // Callback when an item is dropped (you will need this to update your state)
                                            autoScroll={true} // Enable auto-scrolling when the pointer is close to the edge of the Reorder component (optional), defaults to true
                                            disabled={false} // Disable reordering (optional), defaults to false
                                            disableContextMenus={true} // Disable context menus when holding on touch devices (optional), defaults to true
                                            // placeholder={
                                            //     <div className="custom-placeholder" /> // Custom placeholder element (optional), defaults to clone of dragged element
                                            // }
                                        >
                                            {formik.values[props.name].map((file, index) => (
                                                <div className="thumb" key={index}>
                                                    <div className="thumbInner">
                                                        {getFileExtension(file) === 'png' ||
                                                        getFileExtension(file) === 'jpg' ||
                                                        getFileExtension(file) === 'jpeg' ? (
                                                            <div className="thumbCnt">
                                                                {isValidHttpUrl(file) ? (
                                                                    <img
                                                                        src={`${file}`}
                                                                        className="img"
                                                                        id={'thumb_' + index}
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={`${
                                                                            global.site_url
                                                                        }/uploads/${
                                                                            props.isCompress
                                                                                ? props.folder +
                                                                                  '/compressed'
                                                                                : props.folder
                                                                        }/${file}`}
                                                                        className="img"
                                                                        id={'thumb_' + index}
                                                                    />
                                                                )}

                                                                <div className="fileActions">
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                                index,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="viewBtn material-icons"
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="thumbCnt">
                                                                    <div className="defaultThumb">
                                                                        <span className="material-icons">
                                                                            {getFileExtension(
                                                                                file,
                                                                            ) === 'pdf'
                                                                                ? 'picture_as_pdf'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'doc' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'docx'
                                                                                ? 'description'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp4' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpeg'
                                                                                ? 'movie'
                                                                                : getFileExtension(
                                                                                      file,
                                                                                  ) === 'mpga' ||
                                                                                  getFileExtension(
                                                                                      file,
                                                                                  ) === 'mp3'
                                                                                ? 'volume_up'
                                                                                : 'description'}
                                                                        </span>
                                                                    </div>
                                                                    <div className="fileActions">
                                                                        <span
                                                                            onClick={() =>
                                                                                removeFile(
                                                                                    file,
                                                                                    props.name,
                                                                                    index,
                                                                                )
                                                                            }
                                                                            className="cancelBtn material-icons"
                                                                        >
                                                                            delete
                                                                        </span>
                                                                        <span
                                                                            onClick={() =>
                                                                                viewFile(
                                                                                    file,
                                                                                    props.name,
                                                                                )
                                                                            }
                                                                            className="viewBtn material-icons"
                                                                        >
                                                                            visibility
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </Reorder>
                                    ) : (
                                        formik.values[props.name].map((file, index) => (
                                            <div className="thumb" key={index}>
                                                <div className="thumbInner">
                                                    {getFileExtension(file) === 'png' ||
                                                    getFileExtension(file) === 'jpg' ||
                                                    getFileExtension(file) === 'jpeg' ? (
                                                        <div className="thumbCnt">
                                                            {isValidHttpUrl(file) ? (
                                                                <img
                                                                    src={`${file}`}
                                                                    className="img"
                                                                    id={'thumb_' + index}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={`${
                                                                        global.site_url
                                                                    }/uploads/${
                                                                        props.isCompress
                                                                            ? props.folder +
                                                                              '/compressed'
                                                                            : props.folder
                                                                    }/${file}`}
                                                                    className="img"
                                                                    id={'thumb_' + index}
                                                                />
                                                            )}

                                                            <div className="fileActions">
                                                                <span
                                                                    onClick={() =>
                                                                        removeFile(
                                                                            file,
                                                                            props.name,
                                                                            index,
                                                                        )
                                                                    }
                                                                    className="cancelBtn material-icons"
                                                                >
                                                                    delete
                                                                </span>
                                                                <span
                                                                    onClick={() =>
                                                                        viewFile(file, props.name)
                                                                    }
                                                                    className="viewBtn material-icons"
                                                                >
                                                                    visibility
                                                                </span>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <div className="thumbCnt">
                                                                <div className="defaultThumb">
                                                                    <span className="material-icons">
                                                                        {getFileExtension(file) ===
                                                                        'pdf'
                                                                            ? 'picture_as_pdf'
                                                                            : getFileExtension(
                                                                                  file,
                                                                              ) === 'doc' ||
                                                                              getFileExtension(
                                                                                  file,
                                                                              ) === 'docx'
                                                                            ? 'description'
                                                                            : getFileExtension(
                                                                                  file,
                                                                              ) === 'mp4' ||
                                                                              getFileExtension(
                                                                                  file,
                                                                              ) === 'mpeg'
                                                                            ? 'movie'
                                                                            : getFileExtension(
                                                                                  file,
                                                                              ) === 'mpga' ||
                                                                              getFileExtension(
                                                                                  file,
                                                                              ) === 'mp3'
                                                                            ? 'volume_up'
                                                                            : 'description'}
                                                                    </span>
                                                                </div>
                                                                <div className="fileActions">
                                                                    <span
                                                                        onClick={() =>
                                                                            removeFile(
                                                                                file,
                                                                                props.name,
                                                                                index,
                                                                            )
                                                                        }
                                                                        className="cancelBtn material-icons"
                                                                    >
                                                                        delete
                                                                    </span>
                                                                    <span
                                                                        onClick={() =>
                                                                            viewFile(
                                                                                file,
                                                                                props.name,
                                                                            )
                                                                        }
                                                                        className="viewBtn material-icons"
                                                                    >
                                                                        visibility
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </>
                            ) : null}
                        </>
                    </aside>
                </section>
            </div>
        </>
    )
}

export default Uploader
