import React, { useContext, useEffect } from 'react'
import CustomContainer from '../../../components/customContainer'
import Architecture from '../../../components/architecture'
import VersionHistory from '../../../components/versionHistory'
import Footer from '../../../components/footer'

function Home() {
    return (
        <>
            <CustomContainer />
            <Architecture />
            <VersionHistory />
            <Footer />
        </>
    )
}
export default Home
