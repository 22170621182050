import { React, useEffect, useState, useContext } from 'react'
import SettingContext from '../../common/context/setting/settingContext'
import { Link, useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import moment from 'moment'
import './style.css'
function VersionHistory() {
    const [tableBody, setTableBody] = useState([])

    const settingcontext = useContext(SettingContext)
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingcontext
    const formik = useFormik({
        initialValues: {
            searchterm: '',
            page: 1,
            limit: 5,
            count: 1,
            order: '',
            orderby: 'vi.date, desc',
            filters: {
                emp_email: {
                    value: '1',
                    type: 'in',
                    field: 'vi.enable',
                },
            },
            table: 'version_info',
        },
        onSubmit: (values) => {
            //values.filters.name.value = values.name;
            searchTable(values, 'bitVersion')
        },
    })
    useEffect(() => {
        formik.handleSubmit()
        return () => {
            //formik.values.filters.name.value = "";
        }
    }, [])
    useEffect(() => {
        if (table_data && table_data.from === 'bitVersion' && table_data.records) {
            setTableBody(table_data.records.length ? table_data.records : [])
        }
    }, [table_data])
    const [toggleClass, setToggleClass] = useState(1)
    return (
        <div className="versionHistory customContainer hmVrsnHstry" id="versionHistory">
            <h3 className="alTitle">
                Version <span>History</span>
            </h3>
            <div
                className="nav nav-pills"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
            >
                {tableBody
                    .filter((e) => e.enable === 1)
                    .map((e, i) => {
                        let year = moment(e.date, 'YYYY-MM').format('YYYY')
                        let month = moment(e.date, 'YYYY-MM').format('MMMM')

                        return (
                            <a
                                className={toggleClass === i + 1 ? 'nav-link active' : 'nav-link'}
                                id={`v-pills-${month}-${year}-tab`}
                                href={`#v-pills-${month}-${year}`}
                                role="tab"
                                aria-controls={`v-pills-${year}-${month}`}
                                aria-selected="true"
                                onClick={() => setToggleClass(i + 1)}
                            >
                                {`${month}, ${year}`}
                            </a>
                        )
                    })}
                <Link to="/version" className="rdrVrLnk nav-link">
                    More Versions <span className="material-icons ml-2">open_in_new</span>
                </Link>
            </div>
            {console.log('tableBody', tableBody)}
            <div className="tab-content text-align" id="v-pills-tabContent">
                {tableBody
                    .filter((e) => e.enable === 1)
                    .map((e, i) => {
                        let year = moment(e.date, 'YYYY-MM').format('YYYY')
                        let month = moment(e.date, 'YYYY-MM').format('MMMM')

                        return (
                            <>
                                <div
                                    className={
                                        toggleClass === i + 1 ? 'tab-pane fade show active' : 'none'
                                    }
                                    id={`v-pills-${month}-${year}`}
                                    role="tabpanel"
                                    aria-labelledby={`v-pills-${month}-${year}-tab`}
                                >
                                    {e.version_doc && (
                                        <a
                                            className="dwldVrsnBtn"
                                            href={`${global.site_url}/uploads/docs/${e.version_doc}`}
                                            download={`${e.version_doc}`}
                                        >
                                            <span className="material-icons mr-2">
                                                cloud_download
                                            </span>{' '}
                                            Download Version Document
                                        </a>
                                    )}
                                    <div dangerouslySetInnerHTML={{ __html: e.info }}></div>
                                </div>
                            </>
                        )
                    })}
            </div>
        </div>
    )
}
export default VersionHistory
