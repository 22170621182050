import React, { useContext, useState, useEffect } from 'react'
import { Button, IconButton } from '@material-ui/core'
import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CustomDialog from '../../../common/components/organisms/Dialog'
import SettingContext from '../../../common/context/setting/settingContext'
import { dateTimeFormatFunction } from '../../../common/common/components'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../../common/components/molecules/Loaders/index'
const Projects = (props) => {
    const settingContext = useContext(SettingContext)
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingContext

    const [product, setProduct] = useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const data = props.data
    const toggleFullScreenPopup = props.function
    let apiData = props.apiData

    // const validationArray = Yup.object({
    //     name: Yup.string().required('Required!'),
    //     site_type: Yup.string().required('Required!'),
    // })
    const formik = useFormik({
        initialValues: {
            site_id: 0,
            active_apis: [],
            all_apis: [],
            table: 'site_settings',
        },
        validateOnBlur: false,
        // validationSchema: validationArray,
        onSubmit: (values) => {
            delete values.site_id
            searchTableAction(values)
        },
    })

    useEffect(() => {
        if (data.status === 'apiManage') {
            formik.values.site_id = 'AS0001'
            searchTable(
                {
                    limit: 1,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {
                        id: {
                            value: 'AS0001',
                            type: 'in',
                            field: 'st.site_id',
                        },
                    },
                    table: 'site_settings',
                },
                'apiManagement',
            )
        } else if (data.status === 'new') {
            resetFrom()
        }
        return () => {
            resetFrom()
            clearResponseSetting()
        }
    }, [data.status])

    useEffect(() => {
        console.log(apiData, 'apidddd')
        formik.values.all_apis = props.apiData
    }, [props.apiData])

    useEffect(() => {
        if (table_data && table_data.from === 'apiManagement' && data.status === 'edit') {
            console.log(table_data, 'apiManagement')
            const record = table_data.records[0]
            formik.setFieldValue('active_apis', record.active_apis.split(','))
            formik.setFieldValue('all_apis', record.all_apis.split(','))

            // formik.setFieldValue('id', record.id)
            // formik.setFieldValue('name', record.name)
            // formik.setFieldValue('site_type', record.site_type)
            // formik.setFieldValue('enable', record.enable)
        }
    }, [table_data])
    /*useEffect(() => {
    if (single_configuration.record && data.status === "edit") {
      const template = single_configuration.record;

      setProduct(template);
      formik.values.id = template.id;
      formik.values.type = template.type;
      formik.values.question = template.question;
      formik.values.variable = template.variable;

      if (template.type === "checkbox") {
        formik.setFieldValue("value", template.value === "1" ? true : false);
      } else {
        formik.setFieldValue("value", template.value);
      }
    } else {
      resetFrom();
    }
  }, [single_configuration]);*/
    useEffect(() => {
        console.log(responseStatusSetting, 'responseStatusSetting')
        if (
            responseStatusSetting &&
            responseStatusSetting.status === 'success' &&
            responseStatusSetting.from === 'CommunicationSearchTable'
        ) {
            searchTable(
                {
                    limit: 20,
                    page: 1,
                    order: '',
                    orderby: '',
                    filters: {},
                    table: 'projects',
                },
                'editProjects',
            )
            toggleFullScreenPopup(false)
        }
        return () => {
            clearResponseSetting()
        }
    }, [responseStatusSetting])
    console.log('template.value', formik.values.value)

    const resetFrom = () => {
        formik.values.site_id = ''
        formik.values.active_apis = []
        formik.values.all_apis = []
        setProduct(null)
        formik.handleReset()
    }

    const emailTemplate = []

    emailTemplate.push({
        type: 'check',
        class: 'empPermissions d-flex align-items-center flex-wrap',
        name: 'active_apis',
        options: apiData.map((item) => {
            let valueChanged = {}
            valueChanged.id = item
            valueChanged.description = 'Url'
            return valueChanged
        }),
        formik: formik,
    })
    // {
    //     type: 'check',
    //     class: 'empPermissions d-flex align-items-center flex-wrap',
    //     name: 'all_apis',
    //     formik: formik,
    //     options: permissionSelect,
    // },
    // )

    return (
        <FullScreenPopup
            modaltitle={data.status === 'apiManage' ? 'API Management' : `EDIT GIT DOMAIN`}
            open={data.status === 'apiManage' ? true : false}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <h2 className="dashTitle">API Permissions</h2>
                            <div className="row">{Object.values(mapData(emailTemplate))}</div>

                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default Projects
