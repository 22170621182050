//LOGO
export const LOGO = '/assets/svg/logo.svg'

//SITE NAME
export const SITE_NAME = 'The Asset Portal'

// SIDE PANEL LIST
export const SIDE_PANEL = [
    /* {
    label: "Dashboard",
    path: "/dashboard",
    type: "single",
  },*/
    {
        label: 'Version Info',
        path: 'admin',
        type: 'multiple',
        subLinks: [
            {
                label: 'Version',
                path: '/admin/version',
            },
            {
                label: 'Bit Bucket Vesrion',
                path: '/admin/bitversion',
            },
        ],
    },
    {
        label: 'Employee',
        path: 'employees',
        type: 'multiple',
        subLinks: [
            {
                label: 'All',
                path: '/employees/all',
            },
        ],
    },
]

export const ALL_PAGINATION = [
    {
        value: '20',
        show: '20 Results per page',
    },
    {
        value: '40',
        show: '40 Results per page',
    },
    {
        value: '60',
        show: '60 Results per page',
    },
]
