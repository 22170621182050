import { React, useEffect, useState, useContext } from 'react'
// import SettingContext from "../../common/context/setting/settingContext";
import SettingContext from '../../../common/context/setting/settingContext'
import { useFormik } from 'formik'
import moment from 'moment'
import './style.css'
function VersionHistory() {
    const [tableBody, setTableBody] = useState([])

    const settingcontext = useContext(SettingContext)
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingcontext
    const formik = useFormik({
        initialValues: {
            searchterm: '',
            page: 1,
            limit: 20,
            count: 1,
            order: '',
            orderby: 'vi.id, desc',
            filters: {},
            table: 'version_info',
        },
        onSubmit: (values) => {
            //values.filters.name.value = values.name;
            searchTable(values, 'bitVersion')
        },
    })
    useEffect(() => {
        formik.handleSubmit()
        return () => {
            //formik.values.filters.name.value = "";
        }
    }, [])
    useEffect(() => {
        if (table_data && table_data.from === 'bitVersion' && table_data.records) {
            setTableBody(table_data.records.length ? table_data.records : [])
        }
    }, [table_data])
    const [toggleClass, setToggleClass] = useState(1)
    return (
        <div className="versionHistory allVrsnPage customContainer mt-5" id="versionHistory">
            <h3 className="alTitle">
                Version <span>History</span>
            </h3>
            <div className="row">
                <div className="col-2">
                    <div
                        className="nav flex-column nav-pills"
                        id="v-pills-tab"
                        role="tablist"
                        aria-orientation="vertical"
                    >
                        {tableBody
                            .filter((e) => e.enable === 1)
                            .map((e, i) => {
                                let year = moment(e.date, 'YYYY-MM').format('YYYY')
                                let month = moment(e.date, 'YYYY-MM').format('MMMM')

                                return (
                                    <a
                                        className={
                                            toggleClass === i + 1 ? 'nav-link active' : 'nav-link'
                                        }
                                        id={`v-pills-${month}-${year}-tab`}
                                        href={`#v-pills-${month}-${year}`}
                                        role="tab"
                                        aria-controls={`v-pills-${year}-${month}`}
                                        aria-selected="true"
                                        onClick={() => setToggleClass(i + 1)}
                                    >
                                        {`${month}, ${year}`}
                                    </a>
                                )
                            })}
                        {/* <a
              className={toggleClass === 1 ? "nav-link active" : "nav-link"}
              id="v-pills-january-2022-tab"
              data-toggle="pill"
              href="#v-pills-january-2022"
              role="tab"
              aria-controls="v-pills-january-2022"
              aria-selected="true"
              onClick={() => setToggleClass(1)}
            >
              January, 2022
            </a>
            <a
              className={toggleClass === 2 ? "nav-link active" : "nav-link"}
              id="v-pills-december-2021-tab"
              data-toggle="pill"
              href="#v-pills-december-2021"
              role="tab"
              aria-controls="v-pills-december-2021"
              aria-selected="true"
              onClick={() => setToggleClass(2)}
            >
              December, 2021
            </a>
            <a
              className={toggleClass === 3 ? "nav-link active" : "nav-link"}
              id="v-pills-november-2021-tab"
              data-toggle="pill"
              href="#v-pills-november-2021"
              role="tab"
              aria-controls="v-pills-november-2021"
              aria-selected="true"
              onClick={() => setToggleClass(3)}
            >
              November, 2021
            </a>
            <a
              className={toggleClass === 4 ? "nav-link active" : "nav-link"}
              id="v-pills-october-2021-tab"
              data-toggle="pill"
              href="#v-pills-october-2021"
              role="tab"
              aria-controls="v-pills-october-2021"
              aria-selected="true"
              onClick={() => setToggleClass(4)}
            >
              October, 2021
            </a>
            <a
              className={toggleClass === 5 ? "nav-link active" : "nav-link"}
              id="v-pills-september-2021-tab"
              data-toggle="pill"
              href="#v-pills-september-2021"
              role="tab"
              aria-controls="v-pills-september-2021"
              aria-selected="true"
              onClick={() => setToggleClass(5)}
            >
              September, 2021
            </a>
            <a
              className={toggleClass === 6 ? "nav-link active" : "nav-link"}
              id="v-pills-august-2021-tab"
              data-toggle="pill"
              href="#v-pills-august-2021"
              role="tab"
              aria-controls="v-pills-august-2021"
              aria-selected="true"
              onClick={() => setToggleClass(6)}
            >
              August, 2021
            </a>
            <a
              className={toggleClass === 7 ? "nav-link active" : "nav-link"}
              id="v-pills-july-2021-tab"
              data-toggle="pill"
              href="#v-pills-july-2021"
              role="tab"
              aria-controls="v-pills-july-2021"
              aria-selected="false"
              onClick={() => setToggleClass(7)}
            >
              July, 2021
            </a>
            <a
              className={toggleClass === 8 ? "nav-link active" : "nav-link"}
              id="v-pills-june-2021-tab"
              data-toggle="pill"
              href="#v-pills-june-2021"
              role="tab"
              aria-controls="v-pills-june-2021"
              aria-selected="false"
              onClick={() => setToggleClass(8)}
            >
              June, 2021
            </a>
            <a
              className={toggleClass === 9 ? "nav-link active" : "nav-link"}
              id="v-pills-may-2021-tab"
              data-toggle="pill"
              href="#v-pills-may-2021"
              role="tab"
              aria-controls="v-pills-may-2021"
              aria-selected="false"
              onClick={() => setToggleClass(9)}
            >
              May, 2021
            </a>
            <a
              className={toggleClass === 10 ? "nav-link active" : "nav-link"}
              id="v-pills-april-2021-tab"
              data-toggle="pill"
              href="#v-pills-april-2021"
              role="tab"
              aria-controls="v-pills-april-2021"
              aria-selected="false"
              onClick={() => setToggleClass(10)}
            >
              April, 2021
            </a>
            <a
              className={toggleClass === 11 ? "nav-link active" : "nav-link"}
              id="v-pills-march-2021-tab"
              data-toggle="pill"
              href="#v-pills-march-2021"
              role="tab"
              aria-controls="v-pills-march-2021"
              aria-selected="true"
              onClick={() => setToggleClass(11)}
            >
              March, 2021
            </a>
            <a
              className={toggleClass === 12 ? "nav-link active" : "nav-link"}
              id="v-pills-february-2021-tab"
              data-toggle="pill"
              href="#v-pills-february-2021"
              role="tab"
              aria-controls="v-pills-february-2021"
              aria-selected="true"
              onClick={() => setToggleClass(12)}
            >
              February, 2021
            </a>

            <a
              className={toggleClass === 13 ? "nav-link active" : "nav-link"}
              id="v-pills-january-2021-tab"
              data-toggle="pill"
              href="#v-pills-january-2021"
              role="tab"
              aria-controls="v-pills-january-2021"
              aria-selected="false"
              onClick={() => setToggleClass(13)}
            >
              January, 2021
            </a>

            <a
              className={toggleClass === 14 ? "nav-link active" : "nav-link"}
              id="v-pills-december-2020-tab"
              data-toggle="pill"
              href="#v-pills-december-2020"
              role="tab"
              aria-controls="v-pills-december-2020"
              aria-selected="false"
              onClick={() => setToggleClass(14)}
            >
              December, 2020
            </a>

            <a
              className={toggleClass === 15 ? "nav-link active" : "nav-link"}
              id="v-pills-november-2020-tab"
              data-toggle="pill"
              href="#v-pills-november-2020"
              role="tab"
              aria-controls="v-pills-november-2020"
              aria-selected="false"
              onClick={() => setToggleClass(15)}
            >
              November, 2020
            </a>

            <a
              className={toggleClass === 16 ? "nav-link active" : "nav-link"}
              id="v-pills-october-2020-tab"
              data-toggle="pill"
              href="#v-pills-october-2020"
              role="tab"
              aria-controls="v-pills-october-2020"
              aria-selected="false"
              onClick={() => setToggleClass(16)}
            >
              October, 2020
            </a>

            <a
              className={toggleClass === 17 ? "nav-link active" : "nav-link"}
              id="v-pills-september-2020-tab"
              data-toggle="pill"
              href="#v-pills-september-2020"
              role="tab"
              aria-controls="v-pills-september-2020"
              aria-selected="true"
              onClick={() => setToggleClass(17)}
            >
              September, 2020
            </a>
          */}
                    </div>
                </div>
                <div className="col-10">
                    <div className="tab-content text-align" id="v-pills-tabContent">
                        {tableBody
                            .filter((e) => e.enable === 1)
                            .map((e, i) => {
                                let year = moment(e.date, 'YYYY-MM').format('YYYY')
                                let month = moment(e.date, 'YYYY-MM').format('MMMM')

                                return (
                                    <>
                                        <div
                                            className={
                                                toggleClass === i + 1
                                                    ? 'tab-pane fade show active'
                                                    : 'none'
                                            }
                                            id={`v-pills-${month}-${year}`}
                                            role="tabpanel"
                                            aria-labelledby={`v-pills-${month}-${year}-tab`}
                                        >
                                            {e.version_doc && (
                                                <a
                                                    className="dwldVrsnBtn"
                                                    href={`${global.site_url}/uploads/docs/${e.version_doc}`}
                                                    download={`${e.version_doc}`}
                                                >
                                                    <span className="material-icons mr-2">
                                                        cloud_download
                                                    </span>{' '}
                                                    Download Version Document
                                                </a>
                                            )}
                                            <div dangerouslySetInnerHTML={{ __html: e.info }}></div>
                                        </div>
                                    </>
                                )
                            })}
                        {/*       <div
              className={
                toggleClass === 1 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-january-2022"
              role="tabpanel"
              aria-labelledby="v-pills-january-2022-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">January, 2022 (version 2.1)</h2>

                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Paypal API:</span> Third-Party payment gateway PayPal
                    is integrated, now with PayPal also users can pay at
                    checkout.
                  </li>
                  <li>
                    <span>Terminate Auctions:</span> Admin can enter a custom
                    date and time to initiate the termination.
                  </li>
                </ul>
              </div>
            </div>

            <div
              className={
                toggleClass === 2 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-december-2021"
              role="tabpanel"
              aria-labelledby="v-pills-december-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">Descember, 2021 (version 1.12)</h2>

                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Cancel Bid: </span> Admin can cancel the bid of a
                    particular user or users.
                  </li>
                  <li>
                    <span>Rejected bid capture:</span> The system will capture
                    the bids below the reserve price, for future reference or to
                    add custom functionality.
                  </li>
                  <li>
                    <span>Bid increment:</span> The system can show the next bid
                    increment to bid. With reference to the next bid.
                  </li>
                  <li>
                    <span>Multiple tax calculation: </span> The system will
                    calculate tax for multiple locations, with multiple tax
                    variations.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 3 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-november-2021"
              role="tabpanel"
              aria-labelledby="v-pills-november-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">November, 2021 (version 1.11)</h2>

                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Reset pwd from admin: </span> Reset password from
                    admin added. This will reset the password for the admin
                    user.
                  </li>
                  <li>
                    <span>Language Manager: </span> The admin can manage
                    language and change the language variables using the
                    Language manager in the admin panel.
                  </li>
                  <li>
                    <span>Cashfree Integration: </span> Third party payment
                    gateway Cashfree is added. Now in checkout users can pay
                    using the Cashfree service.
                  </li>
                </ul>
                <h3>Bugs</h3>
                <ul className="updtList">
                  <li>
                    <span>Hide Bid History:</span> Fixed
                  </li>
                  <li>
                    <span>change this as Manufacturing Year:</span> Fixed
                  </li>
                  <li>
                    <span>Amount in words says undefined:</span> Fixed
                  </li>
                  <li>
                    <span>I can't able to create a product in test site:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Validation message should be in red:</span> Fixed
                  </li>
                  <li>
                    <span>Text and number were over lapped:</span> Fixed
                  </li>
                  <li>
                    <span>Validation error:</span> Fixed
                  </li>
                  <li>
                    <span>
                      Closed auctions doesn't have any data when i tried to view
                      it:
                    </span>{" "}
                    Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 4 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-october-2021"
              role="tabpanel"
              aria-labelledby="v-pills-october-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">October, 2021 (version 1.10)</h2>
                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Live auction with video streaming:</span> For the live
                    auction, the admin can stream video in the admin panel.
                    Users can see that view preview on the auction view page.
                  </li>
                  <li>
                    <span>Chartmetric API:</span> Chartmetric is a music
                    analyzing tool, it will show music analytics.
                  </li>
                  <li>
                    <span>Refund API: </span> Admin can initiate the refund from
                    the admin panel.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 5 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-september-2021"
              role="tabpanel"
              aria-labelledby="v-pills-september-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">September, 2021 (version 1.9)</h2>

                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>ACH payment:</span> ACH payment is integrated. Users
                    can pay with their bank account during checkout.
                  </li>
                  <li>
                    <span>Avalara Tax:</span> Avalara Tax calculates current
                    rates at the point of purchase. Automate your sales tax
                    process.
                  </li>
                  <li>
                    <span>Dynamic static pages: </span> Static pages can be
                    edited from the admin pannel.
                  </li>
                </ul>
                <h3>Bugs</h3>
                <ul className="updtList">
                  <li>
                    <span>shipping tracking issue:</span> Fixed
                  </li>
                  <li>
                    <span>autoPaying invoices in checkout page issue:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Admin invoice design is broken for sometimes:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Successfully registered two time its showing:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Gender field is missing in profile:</span> Fixed
                  </li>{" "}
                  <li>
                    <span>
                      Passowrd Stregth bar is displaying confirm password:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Bid placed toast messgae displaying two times:</span>{" "}
                    Fixed
                  </li>
                </ul>
                <ul className="updtList">
                  <li>
                    <span>
                      Download Complete manifest is no longer working:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      auction type (Proxy and Hard) not choosing properly:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Barcode, Unit, and State, values are not showing properly:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      User - Recently Listed Section on landing page not showing
                      items again:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Profile details looks overlapped, after we add an addres:
                    </span>{" "}
                    Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 6 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-august-2021"
              role="tabpanel"
              aria-labelledby="v-pills-august-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">August, 2021 (version 1.8)</h2>

                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>North capital Integration:</span> Third-party payment
                    gateway North Capital is integrated. Users can pay using the
                    North Capital service in checkout.
                  </li>
                  <li>
                    <span>Deposit and wallet:</span> Now users can deposit into
                    their wallets, then use wallet amount in checkout. Admin can
                    add wallet amounts to their users from the admin panel.
                  </li>
                </ul>
                <h3>Bugs</h3>
                <ul className="updtList">
                  <li>
                    <span>After search - Logged out:</span> Fixed
                  </li>
                  <li>
                    <span>
                      employee received email but that password is not working:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Company User - Edit - please check null issue:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      From Sold and Unsold listings - remove this "Edit Option":
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Company user forgot email - check &#38; fix "Null" issue:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Admin - edit user -- given data not populating:</span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Company User profile page - Need alignment for this both
                      buttons:
                    </span>{" "}
                    Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 7 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-july-2021"
              role="tabpanel"
              aria-labelledby="v-pills-july-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">July, 2021 (version 1.7)</h2>
                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Test the water auctions:</span> Test the water auction
                    is a new type of auction.
                  </li>
                  <li>
                    <span>Relisting auction:</span> Admin can Relist unsold
                    auctions from listing page.
                  </li>
                  <li>
                    <span>ShipStation integration: </span> ShipStation is a
                    Third-Party shipping service, with that admin can send thier
                    auction items to the winner.
                  </li>
                  <li>
                    <span>UPS and Fedex:</span> UPS and Fedex are Third-Party
                    shipping services, with that admin can send thier auction
                    items to the winner.
                  </li>
                </ul>
                <h3>Bugs</h3>
                <ul className="updtList">
                  <li>
                    <span>
                      After product posted &#38; user also bid &#38; we able to
                      change the start time:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>Back Button is not working:</span> Fixed
                  </li>
                  <li>
                    <span>
                      After Time end product not moving to Archives TAbs it is
                      occur on sometimes:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Lots - Draft - Click the Export all data button it's not
                      working:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      Lost mail - Insert Lost mail &#38; event.first_ name is
                      displaying:
                    </span>{" "}
                    Fixed
                  </li>
                  <li>
                    <span>
                      {" "}
                      Won, Lost &#38; payment - user not able to click button in
                      the mail:
                    </span>{" "}
                    Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 8 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-june-2021"
              role="tabpanel"
              aria-labelledby="v-pills-june-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">June, 2021 (version 1.6)</h2>
                <h3>New</h3>

                <ul className="updtList">
                  <li>
                    <span>Two Factor Aucthentication:</span> Two Factor
                    Authentication added for login.
                  </li>
                  <li>
                    <span>Dynamic Banner in Home page:</span> Admin can change
                    the banner from admin panel.
                  </li>
                  <li>
                    <span>Bidding with dropdown values:</span> The dropdown
                    contains the bid increment values up to 10 increments. User
                    can select one to bid.
                  </li>
                </ul>
                <h3>Bugs</h3>
                <ul className="updtList">
                  <li>
                    <span>Bidding with dropdown values: </span> Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 9 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-may-2021"
              role="tabpanel"
              aria-labelledby="v-pills-may-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">May, 2021 (version 1.5)</h2>
                <h3>New</h3>

                <ul className="updtList">
                  <li>
                    <span>CSV bulk Bidding:</span> Users can download the CSV
                    file, edit it for multiple products and upload to bid.
                  </li>
                  <li>
                    <span>Auction and Lot concept:</span> Multiple lots can be
                    grouped into one auction and can be managed globally.{" "}
                  </li>
                  <li>
                    <span>Add to cart: </span> Add to cart added for won user.
                    Product will be added in card and from there users can
                    checkout.
                  </li>
                </ul>
                <h3>Bugs</h3>

                <ul className="updtList">
                  <li>
                    <span>Better validation in registration: </span> Fixed
                  </li>
                  <li>
                    <span>Phone number Verification: </span> Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 10 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-april-2021"
              role="tabpanel"
              aria-labelledby="v-pills-april-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">April, 2021 (version 1.4)</h2>
                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Appointment Schedule:</span> Users can shedule
                    appointment on checkout page before payment.
                  </li>
                  <li>
                    <span>Paymant gateway.io:</span> PaymentGateway.io is
                    integrated. Users can pay with thier card or ACH during
                    checkout.
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 11 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-march-2021"
              role="tabpanel"
              aria-labelledby="v-pills-march-2021-tab"
            >
              <div className="sftWrpr">
                <h2> March, 2021 (version 1.3)</h2>
                <h3>New</h3>
                <ul className="updtList">
                  <li>
                    <span>Each listing as an Auction:</span> Each product will
                    be posted and awarded separately.
                  </li>
                  <li>
                    <span>Employee Management:</span> Admin can manage employees
                    on the admin panel.
                  </li>
                  <li>
                    <span>Auction and Buynow together:</span> Admin can post a
                    single product as both auction and buynow.
                  </li>
                </ul>
                <h2>Bugs</h2>
                <ul className="updtList">
                  <li>
                    <span>Profile Image edit:</span> Fixed
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 12 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-february-2021"
              role="tabpanel"
              aria-labelledby="v-pills-february-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">February, 2021 (version 1.2)</h2>
                <p>
                  <b>Update 0.6: </b>
                  This update addresses these implementation of the admin new
                  features on.
                  <br />
                  1. Communication Management
                  <br />
                  2. Report Management
                  <br />
                  3. Setting Management
                  <br />
                </p>
                <h2> Admin Communication</h2>
                <ul className="updtList">
                  <li>
                    <span>getSingleStaticPages:</span> Get Single Static Page.{" "}
                  </li>
                  <li>
                    <span>getSingleTemplate:</span> Get Single Template.{" "}
                  </li>
                  <li>
                    <span>staticPages:</span> Get Static Pages.{" "}
                  </li>
                  <li>
                    <span>staticPagesAction:</span> Static Page action.{" "}
                  </li>
                  <li>
                    <span>templateAction:</span> Template Action.{" "}
                  </li>
                  <li>
                    <span>templates:</span> Get Email and SMS Templates.{" "}
                  </li>
                </ul>
                <h2> Admin Report </h2>
                <ul className="updtList">
                  <li>
                    <span>displayReport:</span> Display Report.{" "}
                  </li>
                  <li>
                    <span>downloadReport:</span> Download Report.{" "}
                  </li>
                  <li>
                    <span>getHeaderValues:</span> Get the reports headers.{" "}
                  </li>
                  <li>
                    <span>getRelatedBodyDetails:</span> Get the reports data.{" "}
                  </li>
                </ul>
                <h2> Admin Setting </h2>
                <ul className="updtList">
                  <li>
                    <span>configurationAction:</span> Configuration Action.{" "}
                  </li>
                  <li>
                    <span>configurationVariables:</span> Get All configuration
                    variable.{" "}
                  </li>
                  <li>
                    <span>getSingleConfiguration:</span> Get Single
                    configuration variable.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 13 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-january-2021"
              role="tabpanel"
              aria-labelledby="v-pills-january-2021-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">January, 2021 (version 1.1)</h2>
                <p>
                  <b>Update 1.0: </b>
                  This update addresses these implementation of the admin new
                  features on.
                  <br />
                  1. Employee Management
                  <br />
                  2. Transaction Management
                  <br />
                  3. Return Management
                  <br />
                  4. Refund Management
                  <br />
                </p>
                <h2> Admin Employee</h2>
                <ul className="updtList">
                  <li>
                    <span>allemployees:</span> Get All Employees.{" "}
                  </li>
                  <li>
                    <span>changeStatus:</span> Change Employee status.{" "}
                  </li>
                  <li>
                    <span>employeeAdd:</span> Add.{" "}
                  </li>
                  <li>
                    <span>employeeListing:</span> Get All Employees.{" "}
                  </li>
                  <li>
                    <span>employeeUpdate:</span> Update Employee.{" "}
                  </li>
                  <li>
                    <span>getSingleUser:</span> Get Single Employee Details.{" "}
                  </li>
                  <li>
                    <span>userAction:</span> Employee Action{" "}
                  </li>
                </ul>
                <h2> Admin Transaction</h2>
                <ul className="updtList">
                  <li>
                    <span>paymentLogs:</span> Get all payment logs.{" "}
                  </li>
                  <li>
                    <span>refundLogs:</span> Get all refund logs.{" "}
                  </li>
                </ul>
                <h2> Admin Return </h2>
                <ul className="updtList">
                  <li>
                    <span>changeStatus:</span> Change the Status of the
                    Projects.{" "}
                  </li>
                  <li>
                    <span>invoices:</span> Get All Return Invoice.{" "}
                  </li>
                  <li>
                    <span>refundInvoice:</span> Get a Single Refund Invoice.{" "}
                  </li>
                </ul>
                <h2> Admin Refund </h2>
                <ul className="updtList">
                  <li>
                    <span>authorizeRefundWithCard:</span> Authorize Refund.{" "}
                  </li>
                  <li>
                    <span>getItemPayRecords:</span> Get Item Pay Records.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 14 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-december-2020"
              role="tabpanel"
              aria-labelledby="v-pills-december-2020-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">December, 2020 (version 0.4)</h2>
                <p>
                  <b>Update 0.4: </b>
                  This update addresses these implementation of the admin new
                  features on.
                  <br />
                  1. Product Management
                  <br />
                  2. Auction Management
                  <br />
                  3. Invoice Management
                  <br />
                </p>
                <h2> Admin Product </h2>
                <ul className="updtList">
                  <li>
                    <span>changeStatus:</span> Change Product Status.{" "}
                  </li>
                  <li>
                    <span>listings:</span> Get all project listing.{" "}
                  </li>
                </ul>
                <h2> Admin Auction</h2>
                <ul className="updtList">
                  <li>
                    <span>addtoAuction:</span> Add Project to Auctions.{" "}
                  </li>
                  <li>
                    <span>auctionAction:</span> Auction Actions.{" "}
                  </li>
                  <li>
                    <span>auctionlots:</span> Check Validation.{" "}
                  </li>
                  <li>
                    <span>loginUser:</span> Login Admin User.{" "}
                  </li>
                  <li>
                    <span>resetPassword:</span> Check Validation.{" "}
                  </li>
                  <li>
                    <span>updateProfile:</span> Check Validation.{" "}
                  </li>
                </ul>
                <h2> Admin Invoice </h2>
                <ul className="updtList">
                  <li>
                    <span>changeStatus:</span> Change Invoice status.{" "}
                  </li>
                  <li>
                    <span>invoices:</span> Get All Invoices.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 15 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-november-2020"
              role="tabpanel"
              aria-labelledby="v-pills-november-2020-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">November, 2020 (version 0.3)</h2>
                <p>
                  <b>Update 0.3: </b>
                  This update addresses these implementation of the admin new
                  features on.
                  <br />
                  1. Core
                  <br />
                  2. User Management
                  <br />
                </p>
                <h2> Admin Core </h2>
                <ul className="updtList">
                  <li>
                    <span>checkTokenUser:</span> Login Admin User.{" "}
                  </li>
                  <li>
                    <span>checkValidation:</span> Check Validation.{" "}
                  </li>
                  <li>
                    <span>forgotPassword:</span> Check Validation.{" "}
                  </li>
                  <li>
                    <span>loginUser:</span> Login Admin User.{" "}
                  </li>
                  <li>
                    <span>resetPassword:</span> Check Validation.{" "}
                  </li>
                  <li>
                    <span>updateProfile:</span> Check Validation.{" "}
                  </li>
                </ul>
                <h2> Admin User Management </h2>
                <ul className="updtList">
                  <li>
                    <span>allbuyers:</span> Get All Users.{" "}
                  </li>
                  <li>
                    <span>changeStatus:</span> Change status of the User.{" "}
                  </li>
                  <li>
                    <span>getSingleUser:</span> Get Singer User.{" "}
                  </li>
                  <li>
                    <span>userAction:</span> User Action.{" "}
                  </li>
                  <li>
                    <span>userAdd:</span> Add User.{" "}
                  </li>
                  <li>
                    <span>userListing:</span> List all Users.{" "}
                  </li>
                  <li>
                    <span>userUpdate:</span> Add Update.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 16 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-october-2020"
              role="tabpanel"
              aria-labelledby="v-pills-october-2020-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">October, 2020 (version 0.2)</h2>
                <p>
                  <b>Update 0.2: </b>
                  This update addresses these implementation of the frontend new
                  features on.
                  <br />
                  1. Bidding
                  <br />
                  2. Common Functionality
                  <br />
                  3. Return
                  <br />
                  4. Payment
                  <br />
                  5. Refund
                  <br />
                </p>
                <h2> Frontend Bid </h2>
                <ul className="updtList">
                  <li>
                    <span>cancelItem:</span> Cancel Item from one cart.{" "}
                  </li>
                  <li>
                    <span>changeStatus:</span> Change Status of the cart.{" "}
                  </li>
                  <li>
                    <span>checkAppointments:</span>Check Appointment Details.{" "}
                  </li>
                  <li>
                    <span>pendingCount:</span> Get Pending Cart Items.{" "}
                  </li>
                  <li>
                    <span>saveAddress:</span> Save Address for the cart.{" "}
                  </li>
                  <li>
                    <span>search:</span> Search Cart Itemst.{" "}
                  </li>
                  <li>
                    <span>singleCart:</span> Get Single Cart Details.{" "}
                  </li>
                </ul>
                <h2> Frontend Common </h2>
                <ul className="updtList">
                  <li>
                    <span>commonValues:</span> Get Common Values.{" "}
                  </li>
                  <li>
                    <span>getStaticPage:</span> Get Static pages.{" "}
                  </li>
                </ul>
                <h2> Frontend Return </h2>
                <ul className="updtList">
                  <li>
                    <span>changeStatus:</span> Change one status of the return
                    cart item.{" "}
                  </li>
                  <li>
                    <span>checkAndAddtoCart:</span> Relist Product.{" "}
                  </li>
                  <li>
                    <span>checkAppointments:</span> Single Cart Usage.{" "}
                  </li>
                  <li>
                    <span>getSingleCartDetails:</span> Relist Product.{" "}
                  </li>
                  <li>
                    <span>relistProduct:</span> Relist Product{" "}
                  </li>
                  <li>
                    <span>saveAddress:</span> Save return cart billing address.{" "}
                  </li>
                  <li>
                    <span>search:</span> Get all return products for the user.{" "}
                  </li>
                  <li>
                    <span>singleCart:</span> Single Cart Usage.{" "}
                  </li>
                </ul>
                <h2> Frontend Payment </h2>
                <ul className="updtList">
                  <li>
                    <span>authorizePayment:</span> Authorize the payment.{" "}
                  </li>
                  <li>
                    <span>paymentSuccess:</span> On Payment Success.{" "}
                  </li>
                </ul>
                <h2> Frontend Refund </h2>
                <ul className="updtList">
                  <li>
                    <span>authorizePayment:</span> Authorize Refund.{" "}
                  </li>
                  <li>
                    <span>paymentSuccess:</span> Payment Success Handler.{" "}
                  </li>
                  <li>
                    <span>returnProduct:</span> Return Product Handler.{" "}
                  </li>
                </ul>
              </div>
            </div>
            <div
              className={
                toggleClass === 17 ? "tab-pane fade show active" : "none"
              }
              id="v-pills-september-2020"
              role="tabpanel"
              aria-labelledby="v-pills-september-2020-tab"
            >
              <div className="sftWrpr">
                <h2 className="updtHdr">September, 2020 (version 0.1)</h2>
                <p>
                  <b>Update 0.1: </b>
                  This update addresses these implementation of the frontend new
                  features on:
                  <br />
                  1. Users
                  <br />
                  2. Product
                  <br />
                  3. Auction
                  <br />
                </p>
                <h2> Frontend User </h2>
                <ul className="updtList">
                  <li>
                    <span>addWatchlist:</span> Add to watchlist.{" "}
                  </li>
                  <li>
                    <span>checkTokenUser:</span> Check User Token.{" "}
                  </li>
                  <li>
                    <span>checkValidation:</span> Check User Validation.{" "}
                  </li>
                  <li>
                    <span>forgotPassword:</span> Forgot Password.{" "}
                  </li>
                  <li>
                    <span>loginUser:</span> Login User.{" "}
                  </li>
                  <li>
                    <span>registerUser:</span> Register User.{" "}
                  </li>
                  <li>
                    <span>removeWatchlist:</span> Remove from watchlist.{" "}
                  </li>
                  <li>
                    <span>resetPassword:</span> Reset Password.{" "}
                  </li>
                  <li>
                    <span>sendPhoneVerifyCode:</span> Send Phone Verification
                    Code.{" "}
                  </li>
                  <li>
                    <span>updatePreference:</span> Update User Preference.{" "}
                  </li>
                  <li>
                    <span>updateProfile:</span> Update User Profile.{" "}
                  </li>
                  <li>
                    <span>verifyEmail:</span> Verify Email.{" "}
                  </li>
                  <li>
                    <span>verifyPhoneVerifyCode:</span> Verify Phone
                    Verification Code.{" "}
                  </li>
                </ul>
                <h2> Frontend Product </h2>
                <ul className="updtList">
                  <li>
                    <span>activeSorts:</span> Get Active Sorts Details.{" "}
                  </li>
                  <li>
                    <span>allinvoices:</span> Get All Invoice.{" "}
                  </li>
                  <li>
                    <span>allreturns:</span> Get All Returns.{" "}
                  </li>
                  <li>
                    <span>bidhistory:</span> Get All Bid History.{" "}
                  </li>
                  <li>
                    <span>dashboard:</span> Get Dashboard details.{" "}
                  </li>
                  <li>
                    <span>getdynamicinnercontents:</span> Get Dynamic contects
                    for the pagination for frontend.{" "}
                  </li>
                  <li>
                    <span>getInvoiceDetails:</span> Get all Invoice Details.{" "}
                  </li>
                  <li>
                    <span>getReturnInvoiceDetails:</span> Get all Return Invoice
                    Details.{" "}
                  </li>
                  <li>
                    <span>getSortValues:</span> Sort values in the format for
                    frontend.{" "}
                  </li>
                  <li>
                    <span>imageUploader:</span> Image Uploader Function{" "}
                  </li>
                  <li>
                    <span>invoice:</span> Get Invoice details.{" "}
                  </li>
                  <li>
                    <span>reConfigureEndTime:</span> Re configure the end time
                    for the auction projects.
                  </li>
                  <li>
                    <span>returninvoice:</span> Get Return Invoice details.{" "}
                  </li>
                  <li>
                    <span>search:</span> Search Product.{" "}
                  </li>
                  <li>
                    <span>shortDescribeSCH:</span> Array of elements which add
                    all bidding data.{" "}
                  </li>
                  <li>
                    <span>shortDescribeSCHOnlyAvatar:</span> Add image link to
                    the data.{" "}
                  </li>
                </ul>
                <h2> Frontend Auction </h2>
                <ul className="updtList">
                  <li>
                    <span>getdynamicinnercontents:</span> Get Dynamic contects
                    for the pagination for auction.{" "}
                  </li>
                  <li>
                    <span>search:</span> Search Auction Items.{" "}
                  </li>
                  <li>
                    <span>shortDescribeSCHOnlyAvatar:</span> Add image link to
                    the data.{" "}
                  </li>
                </ul>
              </div>
            </div>
          */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VersionHistory
