import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../../common/components/templates/Layout'
import CustomTable from '../../../common/components/molecules/CustomTable'
import CustomSearch from '../../../common/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
//import { handleRedirectInternal } from "./../../../common/components";
import { useFormik } from 'formik'
//import CommunicationContext from "../../product/context/communication/communicationContext";
import Loaders from '../../../common/components/molecules/Loaders/index'
import SettingContext from '../../../common/context/setting/settingContext'
import AlertContext from '../../../common/context/alert/alertContext'
import { handleRedirectInternal } from '../../../common/common'
import * as Yup from 'yup'
import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import { mapData } from '../../../common/common/components'
import VersionManage from './versionManage'
const BitListVersion = (props) => {
    const alertContext = useContext(AlertContext)
    //const communicationContext = useContext(CommunicationContext);
    const settingcontext = useContext(SettingContext)

    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { setAlert } = alertContext

    /*const {
    all_templates,
    getAllSearchTemplates,
    responseStatus: responseStatusCommuncation,
    clearResponse: clearResponseCommuncation,
  } = communicationContext; */
    console.log(settingcontext, 'settingContext')
    const {
        searchTableAction,
        searchTable,
        table_data,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseSetting,
    } = settingcontext
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        action: '',
    })

    const onClickEdit = (id, secondaryID, type) => {
        /*if (type === "bidcount") {
      setBidHistoryValue(id);
    } else {*/
        toggleFullScreenPopup(true, type, id)
        //}
    }
    let action = props.match.params.action

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id, action: action })
        } else {
            setManage({ popup, status: 'new', id: 0, action: action })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            page: 1,
            limit: 20,
            count: 1,
            order: '',
            orderby: '',
            filters: {},
            table: 'bit_bucket_version_info',
        },
        onSubmit: (values) => {
            //values.filters.name.value = values.name;
            setIsLoading(true)
            searchTable(values, 'bitVersion')
        },
    })
    useEffect(() => {
        formik.handleSubmit()
        return () => {
            //formik.values.filters.name.value = "";
        }
    }, [])

    useEffect(() => {
        if (table_data && table_data.from === 'bitVersion' && table_data.records) {
            setTableBody(table_data.records.length ? table_data.records : [])
            setIsLoading(false)
        }
    }, [table_data])
    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.action = action
        formik.values.page = 1
        formik.values.searchterm = ''
        //getAllSearchTemplates(formik.values)
        searchTable(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Id',
        },
        {
            field: 'date',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'commit_comment',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Commit Info',
        },
        {
            field: 'enable',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            click: true,
            onclick: onClickEdit,
            clickID: 'id',
            secondaryClickID: 'comment',
            clickType: 'edit',
            type: 'button',
            sort: true,
            sortField: 'action',
            firstChild: false,
            disablePadding: false,
            label: 'Edit',
        },
    ]

    const searchInfo = [
        {
            label: 'Name',
            placeholder: 'Enter Name',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'name',
            formik: formik,
        },
    ]
    const validationArray = Yup.object({
        description: Yup.string().required('Required!'),
        question: Yup.string().required('Required!'),
    })
    const formikdata = useFormik({
        initialValues: {
            id: 0,
            description: '',
            question: '',
            active: 1,
            table: 'bit_bucket_version_info',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            searchTableAction(values)
        },
    })
    const details = [
        {
            label: 'Question *',
            placeholder: 'Enter the Question',
            type: 'textarea',
            class: 'col-12',
            name: 'question',
            formik: formikdata,
        },
    ]
    const resetFrom = () => {
        console.log('resetForm')
        formikdata.setFieldValue('id', 0)
        formikdata.setFieldValue('description', '')
        formikdata.setFieldValue('question', '')
        formikdata.setFieldValue('table', 'faq')
        formikdata.handleReset()
    }
    return (
        <Layout>
            <div className="dashboard communications">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                {/*<Button
          variant="outlined"
          className="btnOutlined mr-2"
          onClick={() => toggleFullScreenPopup(true, "new", 0)}
        >
          <span className="material-icons">note_add</span>Add New Single Listing
        </Button>*/}
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={table_data}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={searchTable}
                />
                <VersionManage data={manage} function={toggleFullScreenPopup} />
                {/*<FullScreenPopup
          modaltitle={manage.status === "new" ? "Add New FAQ" : "Edit FAQ"}
          open={manage.popup}
          handleClose={() => toggleFullScreenPopup(false, "new", 0)}
        >
          <div className="dashboard addNewListing mt-4">
            <div className="fspBody">
              <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                <div className="row">{Object.values(mapData(details))}</div>
                <div className="actionButton d-flex justify-content-center align-items-center">
                  <SecondaryButton
                    label="Reset"
                    type="reset"
                    onClick={() => resetFrom()}
                  />
                  <PrimaryButton type="submit" label="Submit" />
                </div>
              </form>
            </div>
          </div>
        </FullScreenPopup>*/}
            </div>
        </Layout>
    )
}
export default BitListVersion
