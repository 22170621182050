import React, { useContext, useEffect } from 'react'
import CustomContainer from '../../../components/customContainer'
import Architecture from '../../../components/architecture'
import VersionHistory from '../../../components/versionHistory'
import Footer from '../../../components/footer'
import './status.css'
// import Highcharts from "highcharts/highstock";
import ReactHighcharts from 'react-highcharts'
// import priceData from 'price.json'
import moment from 'moment'

const options = { style: 'currency', currency: 'USD' }
const numberFormat = new Intl.NumberFormat('en-US', options)
const configPrice = {
    yAxis: [
        {
            offset: 0,

            labels: {
                formatter: function () {
                    return numberFormat.format(this.value)
                },
                x: -15,
                style: {
                    color: '#000',
                    position: 'absolute',
                },
                align: 'left',
            },
        },
    ],
    tooltip: {
        shared: true,
        formatter: function () {
            return (
                numberFormat.format(this.y, 0) +
                '</b><br/>' +
                moment(this.x).format('MMMM Do YYYY, h:mm')
            )
        },
    },
    plotOptions: {
        series: {
            showInNavigator: false,
            gapSize: 6,
        },
    },
    accessibility: {
        enabled: false,
    },
    rangeSelector: {
        selected: false,
    },
    // title: {
    //   text: `Bitcoin stock price`,
    // },
    chart: {
        height: 200,
    },
    credits: {
        enabled: false,
    },
    legend: {
        enabled: false,
    },
    xAxis: {
        type: 'date',
    },
    rangeSelector: {
        buttons: [
            {
                type: 'day',
                count: 1,
                text: '1d',
            },
            {
                type: 'day',
                count: 7,
                text: '7d',
            },
            {
                type: 'month',
                count: 1,
                text: '1m',
            },
            {
                type: 'month',
                count: 3,
                text: '3m',
            },
            {
                type: 'all',
                text: 'All',
            },
        ],
        selected: 4,
    },
    dataLabels: {
        enabled: false,
    },
    series: [
        {
            name: 'Price',
            type: 'spline',

            // data: priceData,
            tooltip: {
                valueDecimals: 2,
            },
        },
    ],
}
function Status() {
    return (
        <>
            <section className="container">
                <div className="dataAnaltyics">
                    <div className="statusBnnr">
                        <h4 className="m-0">
                            large <span className="text-sm">Data Status</span>
                        </h4>
                    </div>
                    <div className="statusHdr">
                        <h4 className="m-0">All Systems Operational</h4>
                    </div>
                    <div className="dataAlysCard">
                        <div className="dataAlyscardbdy">
                            <div className="tit">
                                <h4>ShipStation</h4>
                                <h5>Operational</h5>
                            </div>
                            <div className="statusChrt">
                                <ReactHighcharts config={configPrice}></ReactHighcharts>
                            </div>
                            <div className="value">
                                <h4>90 days ago</h4>
                                <h4>99.96 % uptime</h4>
                                <h4>Today</h4>
                            </div>
                        </div>

                        <div className="mainTit">
                            <h4>System Metrics</h4>
                        </div>
                        <div className="dataAlyscardbdy">
                            <div className="tit">
                                <h4>Javascript API response time</h4>
                                <h5>370 ms</h5>
                            </div>
                            <div className="statusChrt">
                                <ReactHighcharts config={configPrice}></ReactHighcharts>
                            </div>
                        </div>
                        <div className="mainTit">
                            <h4>Past Incidents</h4>
                        </div>
                        <div className="reportedCard">
                            <div className="reportedBdy">
                                <h4>Jul 13, 2022</h4>
                                <p>No incidents reported today.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
export default Status
