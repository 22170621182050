import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../../common/components/atoms/PrimaryButton'
import SecondaryButton from '../../../common/components/atoms/SecondaryButton'
import FullScreenPopup from '../../../common/components/organisms/FullScreenPopup'
import EmployeeContext from '../../../context/employee/empContext'
import AuthContext from '../../../context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../../common/common/components'
import { useFormik } from 'formik'
import { Country as CSCCountry, State as CSCState } from 'country-state-city'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../../common/components/molecules/Loaders/index'

const EmployeeDomain = (props) => {
    const employeeContext = useContext(EmployeeContext)

    const authContext = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const { user } = authContext
    const {
        single_employee,
        getSingleEmployee,
        employeeAction,
        responseStatus: responseStatusEmployee,
    } = employeeContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        git_link_admin: Yup.string().required('Required!'),
        git_link_front: Yup.string().required('Required!'),
        git_link_api: Yup.string().required('Required!'),
        site_link_dev_admin: Yup.string().required('Required!'),
        site_link_dev_front: Yup.string().required('Required!'),
        site_link_dev_api: Yup.string().required('Required!'),
        site_link_live_admin: Yup.string().required('Required!'),
        site_link_live_front: Yup.string().required('Required!'),
        site_link_live_api: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            git_link_admin: '',
            git_link_front: '',
            git_link_api: '',
            site_link_dev_admin: '',
            site_link_dev_front: '',
            site_link_dev_api: '',
            site_link_live_admin: '',
            site_link_live_front: '',
            site_link_live_api: '',
            site_cred_dev_admin_username: '',
            site_cred_dev_buyer_username: '',
            site_cred_dev_seller_username: '',
            site_cred_live_admin_username: '',
            site_cred_live_buyer_username: '',
            site_cred_live_seller_username: '',
            site_cred_dev_admin_password: '',
            site_cred_dev_seller_password: '',
            site_cred_live_admin_password: '',
            site_cred_live_buyer_password: '',
            site_cred_live_seller_password: '',
            gitlab_ci_cd_trigger_admin: '',
            gitlab_ci_cd_trigger_front: '',
            gitlab_ci_cd_trigger_api: '',
            emp_email: '',
        },
        // validationSchema: validationArray,
        onSubmit: (values) => {
            employeeAction(values)
            setIsLoading(true)
        },
    })

    useEffect(() => {
        if (responseStatusEmployee) {
            if (responseStatusEmployee.status === 'success') {
                if (responseStatusEmployee.from === 'employeeAction') {
                    formik.resetForm()
                }
            }
        }
    }, [responseStatusEmployee])

    // useEffect(() => {
    //     resetFrom()
    // }, [props])
    useEffect(() => {
        setIsLoading(false)
        console.log(data, 'dddddata')
    }, [data])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        if (data.status === 'editDomain') {
            setIsLoading(true)
            formik.values.id = data.id
            getSingleEmployee(formik.values)
        } else {
            resetFrom()
            setIsLoading(false)
        }
    }, [data.id])

    useEffect(() => {
        if (single_employee.record && data.status === 'editDomain') {
            console.log('domainnn')
            const user = single_employee.record
            formik.values.emp_email = user.emp_email
            formik.values.git_link_admin = user.git_link_admin
            formik.values.git_link_front = user.git_link_front
            formik.values.git_link_api = user.git_link_api
            formik.values.site_link_dev_admin = user.site_link_dev_admin
            formik.values.site_link_dev_front = user.site_link_dev_front
            formik.values.site_link_dev_api = user.site_link_dev_api
            formik.values.site_link_live_admin = user.site_link_live_admin
            formik.values.site_link_live_front = user.site_link_live_front
            formik.values.site_link_live_api = user.site_link_live_api
            formik.values.site_cred_dev_admin_username = user.site_cred_dev_admin_username
            formik.values.site_cred_dev_buyer_username = user.site_cred_dev_buyer_username
            formik.values.site_cred_dev_seller_username = user.site_cred_dev_seller_username
            formik.values.site_cred_live_admin_username = user.site_cred_live_admin_username
            formik.values.site_cred_live_buyer_username = user.site_cred_live_buyer_username
            formik.values.site_cred_live_seller_username = user.site_cred_live_seller_username
            formik.values.site_cred_dev_admin_password = user.site_cred_dev_admin_password
            formik.values.site_cred_dev_seller_password = user.site_cred_dev_seller_password
            formik.values.site_cred_live_admin_password = user.site_cred_live_admin_password
            formik.values.site_cred_live_buyer_password = user.site_cred_live_buyer_password
            formik.values.site_cred_live_seller_password = user.site_cred_live_seller_password
            formik.values.gitlab_ci_cd_trigger_admin = user.gitlab_ci_cd_trigger_admin
            formik.values.gitlab_ci_cd_trigger_front = user.gitlab_ci_cd_trigger_front
            formik.values.gitlab_ci_cd_trigger_api = user.gitlab_ci_cd_trigger_api
            setReload(!reload)
        } else {
            resetFrom()
        }
        setIsLoading(false)
    }, [single_employee])

    console.log(single_employee, 'single_employee')

    const resetFrom = () => {
        formik.values.git_link_admin = ''
        formik.values.git_link_front = ''
        formik.values.git_link_api = ''
        formik.values.site_link_dev_admin = ''
        formik.values.site_link_dev_front = ''
        formik.values.site_link_dev_api = ''
        formik.values.site_link_live_admin = ''
        formik.values.site_link_live_front = ''
        formik.values.site_link_live_api = ''
        formik.values.site_cred_dev_admin_username = ''
        formik.values.site_cred_dev_buyer_username = ''
        formik.values.site_cred_dev_seller_username = ''
        formik.values.site_cred_live_admin_username = ''
        formik.values.site_cred_live_buyer_username = ''
        formik.values.site_cred_live_seller_username = ''
        formik.values.site_cred_dev_admin_password = ''
        formik.values.site_cred_dev_seller_password = ''
        formik.values.site_cred_live_admin_password = ''
        formik.values.site_cred_live_buyer_password = ''
        formik.values.site_cred_live_seller_password = ''
        formik.values.gitlab_ci_cd_trigger_admin = ''
        formik.values.gitlab_ci_cd_trigger_front = ''
        formik.values.gitlab_ci_cd_trigger_api = ''
        formik.values.emp_email = ''
        formik.handleReset()
        setIsLoading(false)
    }

    const credential = [
        {
            label: 'Dev Admin Username',
            placeholder: 'Enter dev admin username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_admin_username',
            formik: formik,
        },
        {
            label: 'Dev Buyer Username',
            placeholder: 'Enter dev buyer username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_buyer_username',
            formik: formik,
        },
        {
            label: 'Dev Seller Username',
            placeholder: 'Enter dev seller username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_seller_username',
            formik: formik,
        },
        {
            label: 'Live admin Username',
            placeholder: 'Enter live admin username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_live_admin_username',
            formik: formik,
        },
        {
            label: 'Live buyer Username',
            placeholder: 'Enter live buyer username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: '	site_cred_live_buyer_username',
            formik: formik,
        },
        {
            label: 'Live Seller Username',
            placeholder: 'Enter live seller username',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_live_seller_username',
            formik: formik,
        },
        {
            label: 'Dev Admin Password',
            placeholder: 'Enter dev admin password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_admin_password',
            formik: formik,
        },
        {
            label: 'Dev Buyer Password',
            placeholder: 'Enter dev buyer password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_buyer_password',
            formik: formik,
        },
        {
            label: 'Dev Seller Password',
            placeholder: 'Enter dev seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_dev_seller_password',
            formik: formik,
        },
        {
            label: 'Live Admin Password',
            placeholder: 'Enter live admin password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_live_admin_password',
            formik: formik,
        },
        {
            label: 'Live Buyer Password',
            placeholder: 'Enter live buyer password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_live_buyer_password',
            formik: formik,
        },
        {
            label: 'Live Seller Password',
            placeholder: 'Enter live seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_cred_live_seller_password',
            formik: formik,
        },
    ]

    const clone = [
        {
            label: 'GitLab CICD triger ',
            placeholder: 'Enter live seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'gitlab_ci_cd_trigger_admin',
            formik: formik,
        },
        {
            label: 'GitLab CICD triger ',
            placeholder: 'Enter live seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'gitlab_ci_cd_trigger_front',
            formik: formik,
        },
        {
            label: 'GitLab CICD triger ',
            placeholder: 'Enter live seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'gitlab_ci_cd_trigger_api',
            formik: formik,
        },
        {
            label: 'GitLab CICD triger ',
            placeholder: 'Enter live seller password',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'gitlab_ci_cd_trigger_api',
            formik: formik,
        },
    ]

    const domain = [
        {
            label: 'Git Link Admin',
            placeholder: 'Enter admin git link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'git_link_admin',
            formik: formik,
        },
        {
            label: 'Git Link Front',
            placeholder: 'Enter frontend git link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'git_link_front',
            formik: formik,
        },
        {
            label: 'Git Link Api',
            placeholder: 'Enter api git link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'git_link_api',
            formik: formik,
        },
        {
            label: 'Site Link Dev admin',
            placeholder: 'Enter dev admin site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_dev_admin',
            formik: formik,
        },
        {
            label: 'Site Link Dev Front',
            placeholder: 'Enter dev Frontend site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_dev_front',
            formik: formik,
        },
        {
            label: 'Site Link Dev API',
            placeholder: 'Enter dev api site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_dev_api',
            formik: formik,
        },
        {
            label: 'Site Link Live Admin',
            placeholder: 'Enter Admin live site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_live_admin',
            formik: formik,
        },
        {
            label: 'Site Link Live Front',
            placeholder: 'Enter Frontend live site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_live_front',
            formik: formik,
        },
        {
            label: 'Site Link Live API',
            placeholder: 'Enter api live site link',
            type: 'text',
            class: 'col-12 col-sm-6',
            name: 'site_link_live_api',
            formik: formik,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={data.status === 'editDomain' ? 'ADD NEW GIT DOMAIN' : `EDIT GIT DOMAIN`}
            open={data.status === 'editDomain' ? true : false}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <h2 className="dashTitle">GIT DOMAIN</h2>
                            <div className="row">{Object.values(mapData(domain))}</div>
                            <h2 className="dashTitle">GIT CLONE</h2>
                            {Object.values(mapData(clone))}
                            <h2 className="dashTitle">GIT CREDENTIAL</h2>
                            {Object.values(mapData(credential))}
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default EmployeeDomain
