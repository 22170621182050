import React, { useReducer } from 'react'
import ApiManageContext from './apiManageContext'
import apiManageReducer from './apiManageReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'
import { CLEAR_RESPONSE, RESPONSE_STATUS } from './apiManageType'
const ApiManageState = (props) => {
    const initialState = {
        responseStatus: null,
    }
    const [state, dispatch] = useReducer(apiManageReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addApi = async (formData, noAlert) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addAddtionalAPI', formData, '', 'apiStatus'),
            ])
            resp.commonResponse(res.data, 'apiadded', noAlert)
        } catch (err) {
            resp.commonErrorResponse('apiadded', noAlert)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <ApiManageContext.Provider
            value={{
                responseStatus: state.responseStatus,
                addApi,
                clearResponse,
            }}
        >
            {props.children}
        </ApiManageContext.Provider>
    )
}

export default ApiManageState
